// src/components/PopUpComponent.js
import React, { useEffect } from 'react';
import styles from './PopUpComponent.module.css';

const PopUpComponent = ({ title = "Título do Popup", children, isOpen, onClose }) => {
  // Fechar o popup ao pressionar a tecla "Esc"
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  const handleClickOutside = (event) => {
    const targetClassName = event.target.className;
    if (typeof targetClassName === 'string' && targetClassName.includes(styles.overlay)) {
      onClose();
    }
  };
  

  // Não renderiza o popup se não estiver aberto
  if (!isOpen) return null;

  return (
    <div className={styles.overlay} onClick={handleClickOutside}>
      <div className={styles.popup}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PopUpComponent;
