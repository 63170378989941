import React, { useEffect, useState } from 'react';
import { turno } from '../../../DataSeries';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import PlanosComponent from '../../../components/pagesComponents/planosComponents/PlanosComponent';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';

const turnos = turno;

const PlanosProfessorPage = () => {
    const [user, setUser] = useState();
    const [allowedSeries, setAllowedSeries] = useState([]); // Estado para séries permitidas
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                const userData = response.data.user;
                setUser(userData);

                // Verificar se o usuário é professor e possui séries associadas
                if (userData.isProfessor && userData.professorInfo?.length > 0) {
                    const series = userData.professorInfo.map(info => info.serie); // Extrair as séries
                    setAllowedSeries(series); // Definir as séries permitidas
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);

    const itemsPagesList = itemsPagesWithExclusions('Planos', ['Agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);

    return (
        <>
            <PlanosComponent itemsPages={itemsPagesList} accessLevel="professor" seriesAccess={allowedSeries} />
        </>
    );
};

export default PlanosProfessorPage;
