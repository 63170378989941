import React, { useCallback, useEffect, useState } from 'react';
import styles from './CalendarioEditComponent.module.css';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../tenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { FaRegSave } from "react-icons/fa";
import Message from '../tools/Message';

const CalendarioEditComponent = ({ event }) => {
    const [titulo, setTitulo] = useState(event ? event.Titulo : '');
    const [descricao, setDescricao] = useState(event && event.descricao ? EditorState.createWithContent(convertFromRaw(JSON.parse(event.descricao))) : EditorState.createEmpty());
    const [categoriaId, setCategoriaId] = useState(event ? event.categoria : null);
    const [categoriaName, setCategoriaName] = useState(event ? event.categoriaName : '');
    const [categoriaColor, setCategoriaColor] = useState(event ? event.categoriaColor : '');
    const [categorias, setCategorias] = useState([]);
    const [startDate, setStartDate] = useState(event ? moment(event.date, "DD/MM/YYYY HH:mm:ss").toDate() : new Date());
    const [isSaving, setIsSaving] = useState(false);
    const [message, setMessage] = useState(null); // Estado para armazenar a mensagem de feedback

    const tenantId = useTenantId();

    useEffect(() => {
        const fetchCategorias = async () => {
            try {
                const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
                setCategorias(response.data);
            } catch (error) {
                setMessage({ type: 'error', text: 'Erro ao buscar categorias.' });
            }
        };
        fetchCategorias();
    }, [tenantId]);

    // Função para salvar o evento
    const saveEvent = async () => {
        if (!categoriaId) return;

        setIsSaving(true);
        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");
        const rawContent = convertToRaw(descricao.getCurrentContent());

        const eventoAtualizado = {
            tenantId,
            Titulo: titulo,
            descricao: JSON.stringify(rawContent),
            categoria: categoriaId,
            categoriaName,
            categoriaColor,
            date: formattedDate
        };

        try {
            await apiAxios.put(`/api/calendar/${event._id}`, eventoAtualizado);
            setMessage({ type: 'success', text: 'Atualizado com sucesso!' });
        } catch (error) {
            setMessage({ type: 'error', text: 'Erro ao salvar evento.' });
        } finally {
            setIsSaving(false);
            // Oculta a mensagem após 2 segundos
            setTimeout(() => setMessage(null), 2000);
        }
    };

    // Salvar automaticamente após mudanças
    useEffect(() => {
        saveEvent();
    }, [titulo, descricao, categoriaId, categoriaName, categoriaColor, startDate]);

    const handleDescricaoChange = useCallback((editorState) => {
        setDescricao(editorState);
    }, []);

    const defaultSelectedOption = categorias.find(categoria => categoria._id === categoriaId) || null;

    const handleCategoriaChange = (option) => {
        setCategoriaId(option ? option.value : '');
        setCategoriaName(option ? option.label.props.children[1] : '');
        setCategoriaColor(option ? option.label.props.children[0].props.style.backgroundColor : '');
    };

    return (
        <div className={styles.container}>
            {/* Exibe o componente Message com o conteúdo apropriado */}
            {message && <Message type={message.type} message={message.text} />}

            <form className={styles.form}>
                <div className={styles.BasicInfoForm}>
                    <p>Informações Básicas do Evento {isSaving && '(Salvando...)'}</p>
                    <input
                        type="text"
                        placeholder="Título do Evento"
                        value={titulo}
                        onChange={(e) => setTitulo(e.target.value)}
                        required
                    />
                    <Select
                        value={defaultSelectedOption ? {
                            value: defaultSelectedOption._id,
                            label: (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: defaultSelectedOption.category.color,
                                            marginRight: 10
                                        }}
                                    ></span>
                                    {defaultSelectedOption.category.name}
                                </div>
                            )
                        } : null}
                        onChange={handleCategoriaChange}
                        options={categorias.map(categoria => ({
                            value: categoria._id,
                            label: (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: categoria.category.color,
                                            marginRight: 10
                                        }}
                                    ></span>
                                    {categoria.category.name}
                                </div>
                            )
                        }))}
                        placeholder="Selecione uma categoria"
                        className={styles.reactSelect}
                        isClearable
                    />
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Hora"
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="Selecione a data e hora"
                        className={styles.datePicker}
                        required
                    />
                    <Editor
                        editorState={descricao}
                        onEditorStateChange={handleDescricaoChange}
                        wrapperClassName={styles.editorWrapper}
                        editorClassName={styles.editor}
                        toolbarClassName={styles.toolbar}
                        placeholder="Digite a descrição do evento..."
                        toolbar={{
                            options: ['inline', 'fontSize', 'list'],
                            inline: { options: ['bold', 'italic', 'underline'] },
                            fontSize: {
                                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
                            },
                            list: { options: ['unordered', 'ordered'] }
                        }}
                    />
                </div>
                {/* Botão de salvar manual */}
                <div className={styles.botoesDeControle}>
                    <button type="button" onClick={saveEvent}><FaRegSave /> Editar</button>
                </div>
            </form>
        </div>
    );
};

export default CalendarioEditComponent;
