import React from 'react';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import UsuariosComponent from '../../components/pagesComponents/UsersComponents/UsuariosComponent';

const Usuarios = () => {
  const itemsPagesList = itemsPagesWithExclusions('', []);

  return (
    <UsuariosComponent itemsPages={itemsPagesList}/>
  );
};

export default Usuarios;
