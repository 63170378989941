// frontend/src/components/stripe/PaymentsFormComponent.js
import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import apiAxios from '../../api/apiAxios';

const PaymentsFormComponent = ({ tenantId, priceId }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);

    // Carregar métodos de pagamento cadastrados
    useEffect(() => {
        const fetchPaymentMethods = async () => {
            try {
                const response = await apiAxios.get(`/api/stripe/list-payment-methods/${tenantId}`);
                setPaymentMethods(response.data.paymentMethods);
            } catch (error) {
                console.error('Erro ao listar métodos de pagamento:', error);
            }
        };

        fetchPaymentMethods();
    }, [tenantId]);

    // Função para lidar com a exclusão de um cartão
    const handleDeletePaymentMethod = async (paymentMethodId) => {
        try {
            await apiAxios.delete(`/api/stripe/delete-payment-method/${paymentMethodId}`);
            // Atualizar a lista de cartões após exclusão
            setPaymentMethods((prevMethods) => prevMethods.filter((method) => method.id !== paymentMethodId));
            setSuccessMessage('Método de pagamento excluído com sucesso.');
        } catch (error) {
            setErrorMessage('Erro ao excluir método de pagamento.');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);
        setErrorMessage('');
        setSuccessMessage('');

        const cardElement = elements.getElement(CardElement);

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                setErrorMessage(error.message);
                setIsProcessing(false);
                return;
            }

            const response = await apiAxios.post(`/api/stripe/create-subscription/${tenantId}`, {
                paymentMethodId: paymentMethod.id,
                priceId: priceId,
            });

            if (response.data.subscription) {
                setSuccessMessage('Assinatura criada com sucesso!');
                // Atualiza a lista de métodos de pagamento
                setPaymentMethods((prevMethods) => [...prevMethods, paymentMethod]);
            } else {
                setErrorMessage('Erro ao criar assinatura.');
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Ocorreu um erro ao processar o pagamento.');
        }

        setIsProcessing(false);
    };

    return (
        <>
            {/* Formulário para adicionar novo cartão */}
            <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
                <h3>Insira os detalhes do cartão</h3>
                <CardElement options={{ hidePostalCode: true }} />
                <button type="submit" disabled={!stripe || isProcessing}>
                    {isProcessing ? 'Processando...' : 'Adicionar Cartão'}
                </button>
                {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
                {successMessage && <div style={{ color: 'green', marginTop: '10px' }}>{successMessage}</div>}
            </form>

            {/* Lista de cartões cadastrados */}
            <div style={{ marginTop: '20px' }}>
                <h3>Cartões Cadastrados</h3>
                {paymentMethods.length > 0 ? (
                    <ul>
                        {paymentMethods.map((method) => (
                            <li key={method.id}>
                                <strong>**** **** **** {method.card.last4}</strong> - Expira em {method.card.exp_month}/{method.card.exp_year}
                                <button
                                    onClick={() => handleDeletePaymentMethod(method.id)}
                                    style={{ marginLeft: '10px', color: 'red' }}
                                >
                                    Excluir
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Nenhum cartão cadastrado.</p>
                )}
            </div>
        </>
    );
};

export default PaymentsFormComponent;
