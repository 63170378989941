import React, { useEffect, useState } from 'react';
import styles from './CalendarioCategoryViewComponent.module.css';
import { IoClose } from "react-icons/io5";
import useTenantId from '../../../../tenantId';
import apiAxios from '../../../../../api/apiAxios';
import { useNavigate } from 'react-router-dom';
import { RiAddCircleLine } from 'react-icons/ri';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const CalendarioCategoryViewComponent = ({ onClose }) => {
  const [categorias, setCategorias] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoriaToDelete, setCategoriaToDelete] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const tenantId = useTenantId();
  const navigate = useNavigate();

  const fetchCategorias = async () => {
    try {
      const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
      setCategorias(response.data);
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
    }
  };

  useEffect(() => {
    if (tenantId) {
      fetchCategorias();
    }
  }, [tenantId]);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleDeleteClick = (categoria) => {
    setCategoriaToDelete(categoria);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await apiAxios.delete(`/api/calendar/category/${categoriaToDelete._id}`);
      setCategorias(categorias.filter(cat => cat._id !== categoriaToDelete._id));
      setShowDeleteModal(false);
      setSnackbarMessage(`Categoria "${categoriaToDelete.category.name}" deletada com sucesso`);
      setShowSnackbar(true);  // Mostrar Snackbar
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setCategoriaToDelete(null);
  };

  return (
    <div className={styles.allScreen} onClick={handleOutsideClick}>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.itemTop}>
          <h2>Categorias</h2>
          <button onClick={onClose}><IoClose /></button>
        </div>
        <div className={styles.itemCenter}>
          <div className={styles.categories}>
            {categorias.map((categoria) => (
              <div className={styles.content}>
                <div className={styles.item} key={categoria.id} onClick={() => console.log('Categoria selecionada:', categoria)}>
                  <div className={styles.colorBoll} style={{ background: categoria.category.color }}></div>
                  <div className={styles.nameCat}>{categoria.category.name}</div>
                </div>
                <div className={styles.delete} onClick={() => handleDeleteClick(categoria)}><MdOutlineDeleteOutline /></div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.itemBottom}>
          <button onClick={() => navigate('/calendario/category/create')}><RiAddCircleLine /> Criar Novo</button>
        </div>
      </div>

      {showDeleteModal && (
        <div className={styles.deleteModalOverlay} onClick={handleCancelDelete}>
          <div className={styles.deleteModal} onClick={(e) => e.stopPropagation()}>
            <h3>Confirmação de Exclusão</h3>
            <p>Tem certeza de que deseja deletar a categoria "{categoriaToDelete.category.name}"?</p>
            <div className={styles.deleteModalActions}>
              <button onClick={handleConfirmDelete}>Sim</button>
              <button onClick={handleCancelDelete}>Não</button>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setShowSnackbar(false)}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CalendarioCategoryViewComponent;
