import React from 'react';
import useTenantId from '../../../components/tenantId';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import HorariosPageComponent from '../../../components/pagesComponents/HorariosComponents/HorariosPageComponent';

const HorariosProfessorPage = () => {
    const tenantId = useTenantId();
    const itemsPagesList = itemsPagesWithExclusions('Horários', ['Agenda','Informes','Atividades','Chat','Fichas','Cardápio']);

    return (
        <HorariosPageComponent tenantId={tenantId} pagesList={itemsPagesList}  accessLevel="lowAccess" />
    );
};

export default HorariosProfessorPage;
