import React, { useState, useRef } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import apiAxios from '../../../api/apiAxios';
import styles from './InformeComponentsCreate.module.css';
import { app } from '../../../pages/firebase';
import useTenantId from '../../tenantId';
import { IoMdClose } from "react-icons/io";

const InformeComponentsCreate = ({ onClose }) => {
    const [topico, setTopico] = useState('');
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [imagemUrl, setImagemUrl] = useState('');
    const [data, setData] = useState('');
    const [message, setMessage] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar o spinner
    const tenantId = useTenantId();
    const fileInputRef = useRef(null);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        await uploadImage(file);
    };

    const handleImageDrop = async (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        await uploadImage(file);
    };

    const uploadImage = async (file) => {
        if (file) {
            setIsLoading(true); // Exibe o spinner enquanto carrega
            const storage = getStorage(app);
            const storageRef = ref(storage, `images/${file.name}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            setImagemUrl(downloadURL);
            setIsLoading(false); // Oculta o spinner ao terminar
        }
    };

    const removeImage = () => {
        setImagemUrl('');
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await apiAxios.post(`/api/inf/${tenantId}`, {
                topico,
                titulo,
                descricao,
                imagem: imagemUrl,
                data
            });
            setMessage('Informe criado com sucesso!');
            setTopico('');
            setTitulo('');
            setDescricao('');
            setImagemUrl('');
            setData('');
            if (onClose) onClose();
        } catch (error) {
            setMessage('Erro ao criar informe: ' + error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.layout}>
                <p>Informações Básicas do Informe:</p>
                <input
                    type="text"
                    id="topico"
                    value={topico}
                    onChange={(e) => setTopico(e.target.value)}
                    required
                    placeholder='Tópico'
                />
                <input
                    type="text"
                    id="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    required
                    placeholder='Título'
                />
                <textarea
                    id="descricao"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    required
                    placeholder='Descrição'
                />
                <input
                    type="datetime-local"
                    id="data"
                    value={data}
                    onChange={(e) => setData(e.target.value)}
                    required
                    className={styles.date}
                />
                <p>Insira uma imagem:</p>
                <div
                    className={`${styles.imagedroparea} ${isDragging ? styles.dragActive : ''}`}
                    onDrop={handleImageDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onClick={() => fileInputRef.current.click()}
                >
                    {isLoading ? (
                        <div className={styles.spinner}></div>
                    ) : (
                        <>
                            Solte a imagem aqui ou clique para selecionar
                            <input
                                type="file"
                                ref={fileInputRef}
                                accept="image/*"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                        </>
                    )}
                </div>

                {imagemUrl && (
                    <div className={styles.previewContainer}>
                        <img src={imagemUrl} alt="Pré-visualização" className={styles.previewImage} />
                        <button className={styles.removeButton} onClick={removeImage}><IoMdClose /></button>
                    </div>
                )}
                {message && <p className={styles.message}>{message}</p>}
                <div className={styles.layout2}>
                    <button type="submit">Criar Informe</button>
                </div>
            </div>
        </form>
    );
};

export default InformeComponentsCreate;
