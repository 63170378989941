import React from 'react';
import { useParams } from 'react-router-dom';
import useTenantId from '../../../../components/tenantId';
import FichaReceivedComponent from '../../../../components/fichasComponent/responseComponent/FichaReceivedComponent';

const FichaSecretariaReceivedPage = () => {
    const tenantId = useTenantId(); // Obtém tenantId usando o hook
    const { formId } = useParams(); // Obtém o ID do formulário da URL

    return (
        <FichaReceivedComponent formId={formId} tenantIdProp={tenantId} />
    );
};

export default FichaSecretariaReceivedPage;
