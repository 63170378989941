import React, { useState, useEffect, useCallback } from 'react';
import styles from './CalendarioCreateComponent.module.css';
import { useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../tenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSnackbar } from 'notistack';

const CalendarioCreateComponent = () => {
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState(EditorState.createEmpty());
    const [categoriaId, setCategoriaId] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchCategorias = async () => {
            try {
                const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
                setCategorias(response.data);
            } catch (error) {
                enqueueSnackbar('Erro ao buscar categorias.', { variant: 'error' });
            }
        };
        fetchCategorias();
    }, [tenantId, enqueueSnackbar]);

    const handleDescricaoChange = useCallback((editorState) => {
        setDescricao(editorState);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoriaId) {
            enqueueSnackbar('Por favor, selecione uma categoria antes de criar o evento.', { variant: 'warning' });
            return;
        }

        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");
        const rawContent = convertToRaw(descricao.getCurrentContent());

        const novoEvento = {
            tenantId,
            Titulo: titulo,
            descricao: JSON.stringify(rawContent),
            categoria: categoriaId,
            date: formattedDate,
        };

        try {
            await apiAxios.post('/api/calendar', novoEvento); // Remove barra final após `/api/calendar`
            enqueueSnackbar('Evento criado com sucesso!', { variant: 'success' });
            navigate('/calendario');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Erro ao criar evento no calendário.';
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    };

    const selectedCategoria = categorias.find(categoria => categoria._id === categoriaId);
    const placeholderText = selectedCategoria ? (
        <>
            <span
                style={{
                    display: 'inline-block',
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: selectedCategoria.category.color,
                    marginRight: 10
                }}
            ></span>{selectedCategoria.category.name}
        </>
    ) : "Selecione uma categoria";

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.BasicInfoForm}>
                <p>Informações Básicas do Evento</p>
                <input
                    type="text"
                    placeholder="Título do Evento"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    required
                />
                <Select
                    value={selectedCategoria ? { value: selectedCategoria._id, label: selectedCategoria.category.name } : null}
                    onChange={(option) => setCategoriaId(option ? option.value : null)}
                    options={categorias.map(categoria => ({
                        value: categoria._id,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: 12,
                                        height: 12,
                                        borderRadius: '50%',
                                        backgroundColor: categoria.category.color,
                                        marginRight: 10
                                    }}
                                ></span>
                                {categoria.category.name}
                            </div>
                        )
                    }))}
                    placeholder={placeholderText}
                    className={styles.reactSelect}
                    isClearable
                />
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="dd/MM/yyyy HH:mm"
                    placeholderText="Selecione a data e hora"
                    className={styles.datePicker}
                    required
                />
                <Editor
                    editorState={descricao}
                    onEditorStateChange={handleDescricaoChange}
                    wrapperClassName={styles.editorWrapper}
                    editorClassName={styles.editor}
                    toolbarClassName={styles.toolbar}
                    placeholder="Digite a descrição do evento..."
                    toolbar={{
                        options: ['inline', 'fontSize', 'list'],
                        inline: { options: ['bold', 'italic', 'underline'] },
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        },
                        list: { options: ['unordered', 'ordered'] }
                    }}
                />
                <div className={styles.botoesDeControle}>
                    <button type="submit">Criar Evento</button>
                </div>
            </div>
        </form>
    );
};

export default CalendarioCreateComponent;
