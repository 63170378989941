// src/pages/AddTenant.js
import React, { useState } from 'react';
import styles from './addTenant.module.css';

const AddTenant = ({ onClose, onAddTenant }) => {
  const [schoolName, setSchoolName] = useState('');
  const [email, setEmail] = useState('');
  const [planType, setPlanType] = useState('monthly');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Cria o objeto com os dados do novo Tenant
    const newTenant = {
      SchoolName: schoolName,
      email,
      planType,
      description,
      imageUrl,
    };

    try {
      await onAddTenant(newTenant); // Chama a função para adicionar o tenant
      onClose(); // Fecha o modal após a adição
    } catch (error) {
      console.error('Erro ao adicionar tenant:', error);
      alert('Erro ao adicionar tenant.');
    }
  };

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <h2>Adicionar Tenant</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Nome da Escola</label>
            <input
              type="text"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Descrição</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>URL da Imagem</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Tipo de Plano</label>
            <select
              value={planType}
              onChange={(e) => setPlanType(e.target.value)}
            >
              <option value="monthly">Mensal</option>
            </select>
          </div>
          <div className={styles.buttonGroup}>
            <button type="submit">Adicionar</button>
            <button type="button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTenant;
