import React, { useState } from 'react';
import styles from './MateriasEditComponent.module.css';

const MateriasEditComponent = ({
    materia,
    handleEditSubmit,
    handleCloseModal,
    tenantId
}) => {
    const [formData, setFormData] = useState({
        materia: materia.materia.map(item => ({
            materia: item.materia,
            description: item.description,
            color: item.color,
            _id: item._id
        })),
        serie: materia.serie // Série permanece inalterada
    });

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedMaterias = [...formData.materia];
        updatedMaterias[index] = { ...updatedMaterias[index], [name]: value };
        setFormData({ ...formData, materia: updatedMaterias });
    };

    const handleAddMateria = () => {
        setFormData({
            ...formData,
            materia: [
                ...formData.materia,
                { materia: '', description: '', color: '#000000' }
            ]
        });
    };

    const handleRemoveMateria = (indexToRemove) => {
        const updatedMaterias = formData.materia.filter((_, index) => index !== indexToRemove);
        setFormData({ ...formData, materia: updatedMaterias });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const updatedMateria = {
            _id: materia._id,
            tenantId,
            materia: formData.materia,
            serie: formData.serie // Mantém a série original
        };
        handleEditSubmit(updatedMateria);
    };

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            {formData.materia.map((item, index) => (
                <div className={styles.materiaContainer} key={index}>
                    <div className={styles.formGroup}>
                        <label htmlFor={`materia-${index}`}>Nome da matéria:</label>
                        <input
                            id={`materia-${index}`}
                            type="text"
                            name="materia"
                            value={item.materia || ''}
                            onChange={(e) => handleInputChange(e, index)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor={`description-${index}`}>Descrição:</label>
                        <input
                            id={`description-${index}`}
                            type="text"
                            name="description"
                            value={item.description || ''}
                            onChange={(e) => handleInputChange(e, index)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor={`color-${index}`}>Cor:</label>
                        <input
                            id={`color-${index}`}
                            type="color"
                            name="color"
                            className={styles.colorInput}
                            value={item.color || '#000000'}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    </div>
                    <button
                        type="button"
                        className={styles.removeButton}
                        onClick={() => handleRemoveMateria(index)}
                    >
                        Excluir matéria
                    </button>
                </div>
            ))}

            <div className={styles.buttonContainer}>
                <button type="submit" className={styles.submitButton}>Salvar Alterações</button>
                <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={handleAddMateria}
                >
                    Adicionar matéria
                </button>
                <button type="button" className={styles.cancelButton} onClick={handleCloseModal}>
                    Cancelar
                </button>
            </div>
        </form>
    );
};

export default MateriasEditComponent;
