import apiAxios from "../api/apiAxios";

export const getAllRequests = async (tenantId) => {
    try {
        const res = await apiAxios.get(`api/req/requests/${tenantId}`);
        return res?.data?.users;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};

export const deleteRequestById = async (userId) => {
    try {
        const res = await apiAxios.delete(`api/req/requests/${userId}`);
        return res.data?.users; // ou retornar res diretamente, se necessário
    } catch (err) {
        console.error("Erro ao excluir usuário:", err);
        throw err;
    }
};
