import React, { useState, useRef, useEffect, useCallback } from 'react';
import apiAxios from '../../../../api/apiAxios';
import styles from './ItemCardapioCreateComponent.module.css';
import { IoIosArrowUp } from 'react-icons/io';

const ItemCardapioCreateComponent = ({ tenantId, category, onClose, setCategories }) => {
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [isClosing, setIsClosing] = useState(false); // Definir o estado isClosing
  const popupRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await apiAxios.post(`/api/card/item/items/` + tenantId, {
        group: category._id,
        titulo: titulo,
        descricao: descricao,
      });

      const newItem = response.data;

      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat._id === category._id
            ? { ...cat, items: [...cat.items, newItem] }
            : cat
        )
      );

      onClose();
    } catch (error) {
      console.error('Erro ao criar o item:', error.response?.data || error.message);
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsClosing(true); // Definir isClosing como true ao fechar
        setTimeout(onClose, 300); // Aguardar a animação antes de fechar o modal
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <div ref={popupRef} className={`${styles.popup} ${isClosing ? styles.closing : ''}`}>
        <div className={styles.return}>
          <button
            className={`${styles.closeIcon} ${isClosing ? styles.flipDown : ''}`}
            onClick={() => {
              setIsClosing(true);
              setTimeout(onClose, 300); // Aguarda a animação antes de fechar
            }}
            aria-label="Fechar Popup"
          >
            <IoIosArrowUp />
          </button>
        </div>
        <h2>Adicionar novo item à categoria "{category.Titulo}"</h2>
        <form onSubmit={handleSubmit} className={styles.formGroup}>
          <input
            type="text"
            placeholder='Titulo'
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder='Descrição'
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
          <button type="submit" className={styles.addButton}>Adicionar Item</button>
        </form>
      </div>
    </>
  );
};

export default ItemCardapioCreateComponent;
