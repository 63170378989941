import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTenants, signin } from '../actions/UserActions';
import styles from './LoginPage.module.css'
import logo from '../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png'
import { Link } from 'react-router-dom';
import { PiStudentFill } from "react-icons/pi";
import SendEmailChangePass from '../components/SendEmailChangePass';

const LoginPage = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [tenantIds, setTenants] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const toggleModal = () => {
        setIsOpenModal(!isOpenModal);
    };

    const handleLogin = async () => {
        await dispatch(signin(email, password, tenantId));
    };

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                setTenants(response);
            } catch (error) {
                console.error('Error fetching tenants:', error);
            }
        };
        fetchTenants();
    }, []);

    return (
        <div className={styles.all}>
            <div className={styles.formLogin}>
                <img className={styles.logo} src={logo} alt="logo" />
                <form>
                    <div>
                        <label>Email:</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label>Senha:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className={styles.recuperacao}>
                        <Link onClick={toggleModal}>Esqueceu sua senha?</Link>
                    </div>
                    <div>
                        <label>Sua escola:</label>
                        <select value={tenantId} onChange={(e) => setTenantId(e.target.value)}>
                            <option value="">Selecione a sua escola</option>
                            {tenantIds.map(tenant => (
                                <option key={tenant._id} value={tenant._id}>{tenant.SchoolName}</option>
                            ))}
                        </select>
                    </div>
                    <button type="button" onClick={handleLogin}>Entrar</button>
                </form>
            </div>
            <div className={styles.GotAcess}>
                <p>Ainda não tem uma conta ?</p> <a href='http://acesso.educacaoalternativa360.com.br/escola'>Solicitar acesso</a>
            </div>
            <div className={styles.footerLogin}>
                <p>©Alternativo Colaço Soluções Tecnologicas.</p>
                <p>Todos os direitos reservados.</p>
            </div>
            <div className={styles.isAluno}>
                <Link to="http://web.educacaoalternativa360.com.br">
                    <PiStudentFill/>
                    <p>Aluno</p>
                </Link>
            </div>
            {isOpenModal ?
                <SendEmailChangePass CloseModal={toggleModal} />
                :
                <></>
            }
        </div>
    );
};

export default LoginPage;
