// src/hooks/useUserRoles.js

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiAxios from '../../api/apiAxios';

const useUserRoles = () => {
  const isAuthenticated = useSelector(state => state.user.userInfo?.token);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = isAuthenticated && JSON.parse(atob(userInfo.token.split('.')[1]));
  const tenantId = tokenPayload?.tenantId;
  const Id = tokenPayload?._id;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiAxios.get(`/api/user/users/${tenantId}`);
        const user = response?.data?.users?.find(u => u._id === Id);
        setUser(user);
        setLoading(false);
      } catch (error) {
        console.error("Error retrieving user:", error);
        setError("Error retrieving user");
        setLoading(false);
      }
    };

    fetchUser();
  }, [tenantId, Id]);

  const roles = {
    isAdmin: user?.isAdminMaster,
    isDiretor: user?.isAdmin,
    isCoordenaçao: user?.isCoordenaçao,
    isSecretaria: user?.isSecretaria,
    isProfessor: user?.isProfessor,
    isNutricionista: user?.isNutricionista,
  };

  return { roles, loading, error };
};

export default useUserRoles;
