import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './reducers/UserReducer';
import Cookies from 'js-cookie';
import { thunk } from 'redux-thunk';

const store = configureStore({
  reducer: {
    user: UserReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// Carregar o estado inicial do usuário a partir do cookie
const userInfoFromCookies = Cookies.get('userInfo');

if (userInfoFromCookies) {
  const userInfo = JSON.parse(userInfoFromCookies);
  store.dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: userInfo });
}

export default store;
