import apiAxios from "../api/apiAxios";

export const getAllAtividades = async (tenantId) => {
    try {
        const res = await apiAxios.get(`api/atv/${tenantId}`);
        return res.data;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};

export const getAtividade = async (AtvId) => {
    try {
        const res = await apiAxios.get(`api/atv/find/${AtvId}`);
        return res.data;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};

export const deleteAtividade = async (atividadeId) => {
    try {
        // Faça uma solicitação DELETE para o backend para excluir a atividade pelo seu ID
        const response = await apiAxios.delete(`/api/atv/${atividadeId}`);
        return response.data; // Se precisar de algum retorno do backend
    } catch (error) {
        throw new Error(error.response.data.message || error.message);
    }
};
