import React, { useState, useEffect, useRef } from 'react';
import styles from './MateriasSecretaria.module.css';
import { MdDeleteOutline, MdEdit, MdKeyboardArrowLeft, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from "react-icons/md";
import { LuApple } from "react-icons/lu";
import { MdCalendarToday } from "react-icons/md";
import InterfaceStructure from '../../../components/InterfaceStructure';
import { Link } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import { RiMegaphoneLine } from 'react-icons/ri';
import { PiPencilSimpleLineBold } from 'react-icons/pi';
import { TfiAgenda } from 'react-icons/tfi';
import { series } from '../../../DataSeries';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import img from '../../../assets/notFound/OIP.jpg';

const MateriasSecretaria = () => {
    const [materias, setMaterias] = useState([]);
    const [filteredMaterias, setFilteredMaterias] = useState([]);
    const [selectedSerie, setSelectedSerie] = useState('1º ano');
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        materias: [{
            nomeMateria: '',
            descricaoMateria: '',
            corMateria: '',
        }]
    });
    const tenantId = useTenantId();
    const modalRef = useRef(null);

    useEffect(() => {
        const fetchMaterias = async () => {
            try {
                const response = await apiAxios.get('/api/mat/materias');
                const allMaterias = response.data;
                const filtered = allMaterias.filter(materia => materia.serie === selectedSerie);
                setMaterias(allMaterias);
                setFilteredMaterias(filtered);
            } catch (error) {
                console.error('Erro ao buscar matérias:', error);
            }
        };

        fetchMaterias();
    }, [tenantId, selectedSerie]);

    const handleSerieClick = (serie) => {
        setSelectedSerie(serie);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        document.addEventListener('mousedown', handleOutsideClick);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData({
            materias: [{
                nomeMateria: '',
                descricaoMateria: '',
                corMateria: '',
            }]
        });
        document.removeEventListener('mousedown', handleOutsideClick);
    };

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            handleCloseModal();
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedMaterias = [...formData.materias];
        updatedMaterias[index] = { ...updatedMaterias[index], [name]: value };
        setFormData({ materias: updatedMaterias });
    };

    const handleAddMateria = () => {
        setFormData({
            materias: [
                ...formData.materias,
                { nomeMateria: '', descricaoMateria: '', corMateria: '' }
            ]
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const newMateria = {
                tenantId,
                materia: formData.materias.map(materia => ({
                    materia: materia.nomeMateria,
                    description: materia.descricaoMateria,
                    color: materia.corMateria,
                })),
                serie: selectedSerie,
            };
            const response = await apiAxios.post('/api/mat/materias', newMateria);
            const addedMateria = response.data;

            const newMateriasList = [...materias, addedMateria];
            setMaterias(newMateriasList);
            setFilteredMaterias(newMateriasList.filter(materia => materia.serie === selectedSerie));

            handleCloseModal();
        } catch (error) {
            console.error('Erro ao adicionar matérias:', error);
        }
    };

    const handleDeleteMateria = async (id) => {
        try {
            await apiAxios.delete(`/api/mat/materias/${id}`);
            const updatedMaterias = materias.filter(materia => materia._id !== id);
            setMaterias(updatedMaterias);
            setFilteredMaterias(updatedMaterias.filter(materia => materia.serie === selectedSerie));
        } catch (error) {
            console.error('Erro ao deletar matéria:', error);
        }
    };

    const seriesComMaterias = new Set(materias.map(materia => materia.serie));
    const seriesDisponiveis = series.filter(serie => !seriesComMaterias.has(serie));

    const itemsPages = [
        {
            id: 1,
            name: "Painel",
            icon: <MdOutlineSpaceDashboard />,
            isSelect: false,
            link: "/"
        },
        {
            id: 2,
            name: "Agenda",
            icon: <TfiAgenda />,
            isSelect: false,
            link: "/agenda"
        },
        {
            id: 3,
            name: "Planos",
            icon: <MdOutlineClass />,
            isSelect: false,
            link: "/planos"
        },
        {
            id: 4,
            name: "Calendário",
            icon: <MdCalendarToday />,
            isSelect: false,
            link: "/calendario"
        },
        {
            id: 5,
            name: "Cardápio",
            icon: <LuApple />,
            isSelect: false,
            link: "/cardapio"
        },
        {
            id: 6,
            name: "Informes",
            icon: <RiMegaphoneLine />,
            isSelect: false,
            link: "/informes"
        },
        {
            id: 7,
            name: "Atividades",
            icon: <PiPencilSimpleLineBold />,
            isSelect: false,
            link: "/atividades"
        },
        { id: 8, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, isSelect: false, link: "/fichas" },
    ];

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/agenda'><MdKeyboardArrowLeft />Agenda</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Matérias</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        <button onClick={handleOpenModal}><IoIosAddCircleOutline style={{ fontSize: "13px" }} /> Adicionar matéria</button>
                    </div>
                </div>
                <hr style={{ color: "gray", width: "100%", paddingTop: "0px" }} />
                <div className={styles.seriesList}>
                    <div>
                        {series.map((serie, index) => (
                            <button
                                key={index}
                                onClick={() => handleSerieClick(serie)}
                                className={selectedSerie === serie ? styles.selectedButton : styles.button}
                            >
                                {serie}
                            </button>
                        ))}
                    </div>
                </div>
                {filteredMaterias.length === 0 ? (
                    <div className={styles.notFound}>
                        <p>Nenhuma Matéria Encontrado!</p>
                        <img src={img} alt='img'/>
                    </div>
                ) : (
                    <div className={styles.materiasList}>
                        {
                            filteredMaterias.map((materiaItem) => (
                                <>
                                    <div className={styles.actionsList}>
                                        <button type="button" className={styles.updateButton}><MdEdit /></button>
                                        <button type="button" className={styles.removeButton} onClick={() => handleDeleteMateria(materiaItem._id)}>
                                            <MdDeleteOutline />
                                        </button>
                                    </div>
                                    <div key={materiaItem._id} className={styles.materiaCard}>
                                        {materiaItem.materia.map((item, index) => (
                                            <div className={styles.card} key={index}>
                                                <div className={styles.right}>
                                                    <div className={styles.colorBoll} style={{ background: item.color }}></div>
                                                    <h2>{item.materia}</h2>
                                                    <p> ({item.description})</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ))
                        }
                    </div>
                )}
            </div>
            {openModal && (
                <div className={styles.modalOverlay} onClick={handleOutsideClick}>
                    <div className={styles.modalContent} ref={modalRef}>
                        <div className={styles.closeButton}>
                            <button onClick={handleCloseModal}><IoClose /></button>
                        </div>
                        <h2>Adicionar Matéria</h2>
                        <form onSubmit={handleFormSubmit}>
                            {formData.materias.map((materia, index) => (
                                <div key={index}>
                                    <div>
                                        <label>Nome da matéria:</label>
                                        <input
                                            type="text"
                                            name="nomeMateria"
                                            value={materia.nomeMateria}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                    <div>
                                        <label>Descrição:</label>
                                        <input
                                            type="text"
                                            name="descricaoMateria"
                                            value={materia.descricaoMateria}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                    <div>
                                        <label>Cor:</label>
                                        <input
                                            type="color"
                                            name="corMateria"
                                            className={styles.color}
                                            value={materia.corMateria}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div>
                                <label>Série:</label>
                                <select name="serie" value={selectedSerie} onChange={(e) => setSelectedSerie(e.target.value)} required>
                                    {seriesDisponiveis.map((serie, index) => (
                                        <option key={index} value={serie}>{serie}</option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit">Enviar</button>
                            <button type="button" onClick={handleAddMateria}>Adicionar outra matéria</button>
                            <button type="button" onClick={handleCloseModal}>Cancelar</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
        </div>
    );
}

export default MateriasSecretaria;
