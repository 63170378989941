import React, { useEffect, useState } from 'react';
import styles from './HorariosPageComponent.module.css';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import InterfaceStructure from '../../InterfaceStructure';
import apiAxios from '../../../api/apiAxios';
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import CreateCoordenacaoHorarioComponent from './CreateHorarioComponent';
import { itemsPagesWithExclusions } from '../../itemsPage/itemsPage';
import { IoClose } from 'react-icons/io5';
import { series, turno } from '../../../DataSeries';
import EditCoordenacaoHorarioComponent from './EditHorarioComponent';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import imgNotFound from '../../../assets/notFound/OIP.jpg';

const HorariosPageComponent = ({ tenantId, pagesList, accessLevel }) => {
    const [horarios, setHorarios] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [expandedWeek, setExpandedWeek] = useState(null);
    const [expandedHorario, setExpandedHorario] = useState(null);
    const [selectedSerie, setSelectedSerie] = useState(series[0]);
    const [selectedTurno, setSelectedTurno] = useState(turno[0]);
    const [editingHorario, setEditingHorario] = useState(null);

    const itemsPagesList = pagesList;

    useEffect(() => {
        const fetchHorarios = async () => {
            try {
                const response = await apiAxios.get(`/api/horarios/horarios`, { params: { tenantId } });
                setHorarios(response.data);
            } catch (error) {
                console.error('Erro ao obter horários:', error);
            }
        };

        fetchHorarios();
    }, [tenantId]);

    const handleDeleteHorario = async (id) => {
        try {
            await apiAxios.delete(`/api/horarios/horarios/${id}`);
            setHorarios(horarios.filter(horario => horario._id !== id));
        } catch (error) {
            console.error('Erro ao deletar horário:', error);
        }
    };

    const handleHorarioCreated = (horario) => {
        setHorarios([...horarios, horario]);
        setShowForm(false);
    };

    const handleHorarioUpdated = (updatedHorario) => {
        setHorarios(horarios.map(horario =>
            horario._id === updatedHorario._id ? updatedHorario : horario
        ));
        setEditingHorario(null);
    };

    const toggleWeek = (weekDay) => {
        setExpandedWeek(expandedWeek === weekDay ? null : weekDay);
    };

    const toggleHorario = (materiaTitle) => {
        setExpandedHorario(expandedHorario === materiaTitle ? null : materiaTitle);
    };

    const closeForm = () => {
        setShowForm(false);
        setEditingHorario(null);
    };

    const handleClickOutside = (event) => {
        if (event.target === event.currentTarget) {
            closeForm();
        }
    };

    const filteredHorarios = horarios.filter(horario =>
        horario.serie === selectedSerie && horario.turno === selectedTurno
    );

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Horários</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        {accessLevel === 'highAccess' && (
                            <Link onClick={() => setShowForm(!showForm)}><IoIosAddCircleOutline /> {editingHorario ? 'Editar Horário' : 'Adicionar horário'}</Link>
                        )}
                    </div>
                </div>
                {(showForm || editingHorario) && accessLevel === 'highAccess' && (
                    <div className={styles.form} onClick={handleClickOutside}>
                        <div className={styles.form_container}>
                            <div className={styles.form_header}>
                                <h2>{editingHorario ? 'Editar Horário' : 'Adicionar Horário'}</h2>
                                <button className={styles.closeButton} onClick={closeForm}><IoClose /></button>
                            </div>
                            <div className={styles.form_content}>
                                {editingHorario ? (
                                    <EditCoordenacaoHorarioComponent
                                        horarioId={editingHorario._id}
                                        onHorarioUpdated={handleHorarioUpdated}
                                    />
                                ) : (
                                    <CreateCoordenacaoHorarioComponent onHorarioCreated={handleHorarioCreated} />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.horariosList}>
                    <div className={styles.filters}>
                        <select
                            value={selectedSerie}
                            onChange={(e) => setSelectedSerie(e.target.value)}
                        >
                            {series.map((serie) => (
                                <option key={serie} value={serie}>
                                    {serie}
                                </option>
                            ))}
                        </select>
                        <select
                            value={selectedTurno}
                            onChange={(e) => setSelectedTurno(e.target.value)}
                        >
                            {turno.map((turnoItem) => (
                                <option key={turnoItem} value={turnoItem}>
                                    {turnoItem}
                                </option>
                            ))}
                        </select>
                    </div>
                    {filteredHorarios.length > 0 ? (
                        filteredHorarios.map((horario) => (
                            <div key={horario._id} className={styles.horarioItem}>
                                {accessLevel === 'highAccess' && (
                                    <div className={styles.actionButtons}>
                                        <button className={styles.updateButton} onClick={() => setEditingHorario(horario)}><MdEdit /></button>
                                        <button className={styles.removeButton} onClick={() => handleDeleteHorario(horario._id)}><MdDeleteOutline /></button>
                                    </div>
                                )}
                                <h2>{horario.serie} - {horario.turno}</h2>
                                {horario.week.map(week => (
                                    <div className={styles.semana_all} key={week.week_day}>
                                        <div className={styles.semana} onClick={() => toggleWeek(week.week_day)}>
                                            <strong>
                                                {expandedWeek === week.week_day ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                                {week.week_day}
                                            </strong>
                                            <p>{week.TimeStart}-{week.TimeEnd}</p>
                                        </div>
                                        {expandedWeek === week.week_day && week.materias.map(materia => (
                                            <div className={styles.horario} key={materia.title}>
                                                <div className={styles.horario_top} onClick={() => toggleHorario(materia.title)}>
                                                    <p>
                                                        {expandedHorario === materia.title ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                                        {materia.title}
                                                    </p>
                                                    <p>{materia.TimeStart} - {materia.TimeEnd}</p>
                                                </div>
                                                {expandedHorario === materia.title && (
                                                    <div className={styles.horario_bottom}>
                                                        {materia.conteudo}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <div className={styles.notFound}>
                            <p>Nenhuma Matéria Encontrada!</p>
                            <img src={imgNotFound} alt='Nenhuma Matéria Encontrada' />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
    );
};

export default HorariosPageComponent;
