import React, { useEffect, useMemo, useState } from 'react'
import styles from "./HomeCoordenacao.module.css"
import InterfaceStructure from '../../components/InterfaceStructure';
import apiAxios from '../../api/apiAxios';
import Chart from '../../components/DashboardComponents/chart/Chart';
import WidgetSm from '../../components/DashboardComponents/widgetSm/WidgetSm';
// Icons
import { FaUsers } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import {  IoGitPullRequestSharp } from "react-icons/io5";
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';

const HomeCoordenacao = () => {
    const navigate = useNavigate();

    const itemsPagesList = itemsPagesWithExclusions('Painel', ['Agenda','Informes','Atividades','Chat','Fichas','Cardápio']);

    const [userStats, setUserStats] = useState([]);

    const MONTHS = useMemo(
        () => [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Agu",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        []
    );

    useEffect(() => {
        const getStats = async () => {
            try {
                const res = await apiAxios.get("api/user/stats");
                res.data.map((item) =>
                    setUserStats((prev) => [
                        ...prev,
                        { name: MONTHS[item._id - 1], "Active User": item.total },
                    ])
                );
            } catch { }
        };
        getStats();
    }, [MONTHS]);

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Painel</h1>
                        <p>Coordenação</p>
                    </div>
                    <div className={styles.end}>
                        <buttom style={{ background: "white", color: "#555555", border: "solid 0.1px #CCCCCC" }} onClick={() => navigate('/requests')}><IoGitPullRequestSharp style={{ fontSize: "13px" }} />Visualizar Solicitações</buttom>
                        <buttom onClick={() => navigate('/usuarios')}><FaUsers style={{ fontSize: "13px" }} />Visualizar Usuários</buttom>
                    </div>
                </div>
                <div className={styles.bottomAreaInfo}>
                    <Chart
                        data={userStats}
                        title="Análise de Usuários"
                        grid
                        dataKey="Active User"
                    />
                    <WidgetSm />
                </div>
            </div>
        </div>
    );

    return (
        <>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
        </>
    )
}

export default HomeCoordenacao