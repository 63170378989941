import React from 'react';
import { useSelector } from "react-redux";

const useTenantId = () => {
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
  return tokenPayload.tenantId;
};

export default useTenantId;
