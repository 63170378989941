import React, { useState, useEffect } from 'react';
import InterfaceStructure from '../../components/InterfaceStructure';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from "./HomeNutricionista.module.css";
import img from '../../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';

const HomeNutricionista = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.onload = () => setImagesLoaded(true);
        image.src = img;
    }, []);

    const itemsPagesList = itemsPagesWithExclusions('Painel', ['Agenda', 'Informes', 'Atividades', 'Fichas', 'Planos']);

    const images = [
        { id: 1, img: img },
        { id: 2, img: img },
        { id: 3, img: img }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const renderImages = () => (
        images.map((image) => (
            <div className={styles.sliderContainer} key={image.id}>
                <img src={image.img} alt={`slide ${image.id}`} />
            </div>
        ))
    );

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.grid}>
                    {imagesLoaded && (
                        <Slider {...settings} className={styles.sliderHome}>
                            {renderImages()}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
        </div>
    );
};

export default HomeNutricionista;
