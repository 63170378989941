import React, { useEffect, useState } from 'react';
import moment from 'moment';
import apiAxios from '../../../../api/apiAxios';
import useTenantId from '../../../tenantId';
import styles from './AgendaViewComponent.module.css';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const AgendaViewComponent = ({ date, selectedSeries, selectedTurno, onEdit }) => {
    const [calendario, setCalendario] = useState([]);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [statusSuccess, setStatusSuccess] = useState(false);
    const tenantId = useTenantId();


    const fetchCalendario = async (selectedDate) => {
        try {
            const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
            const response = await apiAxios.get(`/api/agenda/by-date/${formattedDate}`, {
                params: { tenantId }
            });

            const filteredEvents = response.data.filter(event =>
                (!selectedSeries || event.serie === selectedSeries) &&
                (!selectedTurno || event.turno === selectedTurno) // Filtra pelo turno
            );

            setCalendario(filteredEvents);
        } catch (error) {
            console.error('Erro ao buscar calendário:', error);
        }
    };

    useEffect(() => {
        fetchCalendario(date);
    }, [date, selectedSeries, selectedTurno]); // Inclua selectedTurno como dependência


    const handleDelete = async (eventId) => {
        try {
            await apiAxios.delete(`/api/agenda/${eventId}`);
            fetchCalendario(date);
            setDeleteSuccess(true);
        } catch (error) {
            console.error('Erro ao deletar evento do calendário:', error);
        }
    };

    const toggleAgendaStatus = async (eventId, currentStatus) => {
        try {
            await apiAxios.patch(`/api/agenda/${eventId}`, {
                agendastatus: !currentStatus
            });
            fetchCalendario(date);
            setStatusSuccess(true);
        } catch (error) {
            console.error('Erro ao atualizar status do evento:', error);
        }
    };

    return (
        <div className={styles.viewContainer}>
            <h2 className={styles.viewContainerTitle}>Eventos para a Série: {selectedSeries || "Todas as Séries"}</h2>
            {calendario.length === 0 ? (
                <p>Nenhum evento encontrado para esta data e série.</p>
            ) : (
                calendario.map(event => (
                    <div key={event._id} className={styles.eventDetails}>
                        <h3>{event.titulo || "Evento Sem Título"}</h3>
                        <p>Data: {moment(event.date).format("DD/MM/YYYY HH:mm")}</p>
                        <p>Série: {event.serie}</p>
                        <p>Turno: {event.turno}</p>
                        <div className={styles.materiasList}>
                            {event.materia.map(materiaItem => (
                                <div
                                    key={materiaItem._id}
                                    className={styles.materiaItem}
                                    style={{ borderLeft: `5px solid ${materiaItem.color || '#ccc'}` }}
                                >
                                    <p><strong>Matéria:</strong> {materiaItem.materia}</p>
                                    <p><strong>Descrição:</strong> {materiaItem.description || "N/A"}</p>
                                    <p><strong>Objetivo do Conhecimento:</strong> {materiaItem.objetivoConhecimento || "N/A"}</p>
                                    <p><strong>Habilidades:</strong> {materiaItem.habilidades || "N/A"}</p>
                                    <p><strong>Metodologia:</strong> {materiaItem.metodologia || "N/A"}</p>
                                    <p><strong>Material:</strong> {materiaItem.material || "N/A"}</p>
                                </div>
                            ))}
                        </div>
                        <div className={styles.actions}>
                            <button onClick={() => onEdit(event._id)} className={`${styles.edit} ${styles.customButton}`}>
                                <BiEditAlt /> Editar
                            </button>
                            <button onClick={() => handleDelete(event._id)} className={`${styles.delete} ${styles.customButton}`}>
                                <MdOutlineDeleteOutline /> Deletar
                            </button>
                            <button
                                onClick={() => toggleAgendaStatus(event._id, event.agendastatus)}
                                className={`${event.agendastatus ? styles.restrict : styles.allow} ${styles.customButton}`}
                            >
                                {event.agendastatus ? 'Restringir Acesso' : 'Liberar Acesso'}
                            </button>

                        </div>
                    </div>
                ))
            )}
            <Snackbar
                open={deleteSuccess}
                autoHideDuration={3000}
                onClose={() => setDeleteSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => setDeleteSuccess(false)}
                    severity="success"
                >
                    Evento deletado com sucesso
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={statusSuccess}
                autoHideDuration={3000}
                onClose={() => setStatusSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => setStatusSuccess(false)}
                    severity="success"
                >
                    Status do evento atualizado com sucesso
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default AgendaViewComponent;
