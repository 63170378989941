import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import InterfaceStructure from '../../../components/InterfaceStructure';
import styles from './CalendarioCoordenacaoPage.module.css';
import CalendarioViewComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioView/CalendarioViewComponent';
import useTenantId from '../../../components/tenantId';
import moment from 'moment';
import apiAxios from '../../../api/apiAxios';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import CalendarioCategoryViewComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioView/Category/CalendarioCategoryViewComponent';
import { BiCategory } from "react-icons/bi";
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import CalendarioComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioComponent';

const CalendarioCoordenacaoPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Calendário', ['Agenda','Informes','Atividades','Chat','Fichas','Cardápio']);

  return (
    <div>
      <CalendarioComponent itemsPages={itemsPagesList} accessLevel="high"/>
    </div>
  );
};

export default CalendarioCoordenacaoPage;
