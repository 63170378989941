import React, { useEffect, useState } from 'react';
import styles from './AtividadesSecretariaPage.module.css';
import InterfaceStructure from '../../../components/InterfaceStructure';

// Icons
import { MdOutlineSpaceDashboard, MdCalendarToday, MdOutlineClass, MdDeleteOutline, MdOutlineFormatAlignJustify } from "react-icons/md";
import { PiPencilSimpleLineBold, PiStudent } from "react-icons/pi";
import { LuApple } from "react-icons/lu";
import { RiMegaphoneLine, RiEdit2Line } from "react-icons/ri";
import { TfiAgenda } from 'react-icons/tfi';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from "react-icons/io";
import { BiSolidShapes } from "react-icons/bi";

import { series, turno } from "../../../DataSeries";
import { deleteAtividade, getAllAtividades } from '../../../actions/AtividadesActions';
import { useSelector } from 'react-redux';

import { Pagination } from '@mui/material';

import NotFoundImg from "../../../assets/not-found.png";
import Loading from "../../../components/Loading/Loading"
import { IoChatboxEllipsesOutline } from 'react-icons/io5';

const AtividadesSecretariaPage = () => {
    const itemsPerPage = 10;
    const itemsPages = [
        {
            id: 1,
            name: "Painel",
            icon: <MdOutlineSpaceDashboard />,
            isSelect: false,
            link: "/"
        },
        {
            id: 2,
            name: "Agenda",
            icon: <TfiAgenda />,
            isSelect: false,
            link: "/agenda"
        },
        {
            id: 3,
            name: "Planos",
            icon: <MdOutlineClass />,
            isSelect: false,
            link: "/planos"
        },
        {
            id: 4,
            name: "Calendário",
            icon: <MdCalendarToday />,
            isSelect: false,
            link: "/calendario"
        },
        {
            id: 5,
            name: "Cardápio",
            icon: <LuApple />,
            isSelect: false,
            link: "/cardapio"
        },
        {
            id: 6,
            name: "Informes",
            icon: <RiMegaphoneLine />,
            isSelect: false,
            link: "/informes"
        },
        {
            id: 7,
            name: "Atividades",
            icon: <PiPencilSimpleLineBold />,
            isSelect: true,
            link: "/atividades"
        },
        {
            id: 8,
            name: "Chat",
            icon: <IoChatboxEllipsesOutline />,
            isSelect: false,
            link: "/chat"
        },
        { id: 8, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, isSelect: false, link: "/fichas" },
    ];

    const [atividades, setAtividades] = useState([]);
    const [serieSelecionada, setSerieSelecionada] = useState('1º ano');
    const [turnoSelecionado, setTurnoSelecionado] = useState('Manhã');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

    useEffect(() => {
        const fetchAtividades = async () => {
            try {
                const atividadesData = await getAllAtividades(tokenPayload.tenantId);
                setAtividades(atividadesData);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao obter as atividades:', error);
                setLoading(false);
            }
        };

        fetchAtividades();
    }, [tokenPayload.tenantId]);

    const handleSerieChange = (event) => {
        setSerieSelecionada(event.target.value);
    };
    const handleTurnoChange = (event) => {
        setTurnoSelecionado(event.target.value);
    };

    const handleDeleteAtividade = async (atividadeId) => {
        try {
            await deleteAtividade(atividadeId);
            const updatedAtividades = atividades.filter(atividade => atividade._id !== atividadeId);
            setAtividades(updatedAtividades);
        } catch (error) {
            console.error('Erro ao excluir a atividade:', error);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const atividadesFiltradas = atividades.filter((atividade) =>
        (!serieSelecionada || atividade.serie === serieSelecionada) &&
        (!turnoSelecionado || atividade.turno === turnoSelecionado)
    );

    const startIndex = (page - 1) * itemsPerPage;
    const currentAtividades = atividadesFiltradas.slice(startIndex, startIndex + itemsPerPage);

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Atividades</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        <Link style={{ background: "white", color: "gray", border: "0.4px solid #ccc" }} to="/atividades/alunos"><PiStudent /> Acompanhar Alunos</Link>
                        <Link to="/atividades/create"><IoIosAddCircleOutline /> Adicionar Avaliação</Link>
                    </div>
                </div>
                <div className={styles.contentAtividades}>
                    <div className={styles.elementAtividadeLeft}>
                        <div>
                            <p>Série</p>
                            <select onChange={handleSerieChange} value={serieSelecionada} className={styles.seriesSelect}>
                                {series.map((serie) => (
                                    <option key={serie} value={serie}>
                                        {serie}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <p>Turno</p>
                            <select onChange={handleTurnoChange} value={turnoSelecionado} className={styles.turnoSelect}>
                                {turno.map((turno) => (
                                    <option key={turno} value={turno}>
                                        {turno}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={styles.elementAtividadeRight}>
                        {loading ? (
                            <Loading />
                        ) : (
                            currentAtividades.length >= 1 ? (
                                <>
                                    <div className={styles.all}>
                                        {currentAtividades.map((atividade) => (
                                            <div className={styles.item} key={atividade._id}>
                                                <div className={styles.sec1}>
                                                    <BiSolidShapes />
                                                </div>
                                                <div className={styles.sec2}>
                                                    <div className={styles.tit}>{atividade.titulo}</div>
                                                    <div className={styles.mat}>{atividade.materia} - {atividade.questoes.length} Questões</div>
                                                </div>
                                                <div className={styles.sec3}>
                                                    <div className={styles.edit}><RiEdit2Line /></div>
                                                    <div className={styles.delete} onClick={() => handleDeleteAtividade(atividade._id)}><MdDeleteOutline /></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div style={{ width: '100%', height: '300px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img style={{ width: "300px" }} src={NotFoundImg} alt="img" />
                                </div>
                            )
                        )}

                        <div className={styles.pagination}>
                            <Pagination
                                count={Math.ceil(atividadesFiltradas.length / itemsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                classes={{ ul: styles.pagination }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
    );
};

export default AtividadesSecretariaPage;
