import React from 'react'
import styles from './chart.module.css'
import {
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

const Chart = ({ title, data, dataKey, grid }) => {
    return (
        <div className={styles.chart}>
            <h3 className={styles.chartTitle}>{title}</h3>
            <ResponsiveContainer className={styles.ResponsiveContainer} aspect={4 / 1}>
                <LineChart data={data}>
                    <XAxis dataKey="name" stroke="#ed3237" />
                    <Line type="monotone" dataKey={dataKey} stroke="#ed3237" />
                    <Tooltip />
                    {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart