import React, { useEffect, useState } from 'react';
import FrequenciaComponent from '../../../components/pagesComponents/FrequenciaComponents/FrequenciaComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import apiAxios from '../../../api/apiAxios';
import { useSelector } from 'react-redux';

const FrequenciaProfessorPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Frequencia', ['Agenda', 'Informes', 'Atividades', 'Chat', 'Fichas', 'Cardápio']);
  const [user, setUser] = useState();
  const [allowedSeries, setAllowedSeries] = useState([]); // Estado para séries permitidas
  const [allowedTurnos, setAllowedTurnos] = useState([]); // Estado para turnos permitidos
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
        const userData = response.data.user;
        setUser(userData);

        // Verificar se o usuário é professor e possui séries e turnos associados
        if (userData.isProfessor && userData.professorInfo?.length > 0) {
          const series = userData.professorInfo.map(info => info.serie); // Extrair as séries
          const turnos = userData.professorInfo.map(info => info.turno); // Extrair os turnos
          setAllowedSeries(series); // Definir as séries permitidas
          setAllowedTurnos(turnos); // Definir os turnos permitidos
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    if (tokenPayload && tokenPayload._id) {
      fetchUser();
    }
  }, [tokenPayload]);

  return (
    <>
      <FrequenciaComponent
        itemsPages={itemsPagesList}
        accessLevel={"high"}
        accessName={"administrador"}
        professorAccess={{ series: allowedSeries, turnos: allowedTurnos }}
      />
    </>
  );
};

export default FrequenciaProfessorPage;
