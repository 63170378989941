import React, { useState } from 'react';
import apiAxios from '../../api/apiAxios';
import styles from './CreateFormComponent.module.css';
import useTenantId from '../../components/tenantId';
import { useSnackbar } from 'notistack';

const CreateFormComponent = ({ onClose = () => {} }) => {
    const { enqueueSnackbar } = useSnackbar();
    const tenantId = useTenantId();
    const [form, setForm] = useState({
        title: '',
        description: '',
        PublicCampaign: false,
        CampaignLimit: 0,
        fields: [],
        tenantId: tenantId
    });

    const [field, setField] = useState({
        name: '',
        type: 'text',
        required: false,
        placeholder: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false); // Novo estado para gerenciar o botão de envio

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleFieldChange = (e) => {
        const { name, value, checked } = e.target;
        setField({
            ...field,
            [name]: name === 'required' ? checked : value
        });
    };

    const addField = () => {
        if (field.name.trim()) {
            setForm(prevForm => {
                const updatedFields = [...prevForm.fields, field];
                return {
                    ...prevForm,
                    fields: updatedFields
                };
            });
            setField({ name: '', type: 'text', required: false, placeholder: '' });
        } else {
            enqueueSnackbar('Nome do campo é obrigatório.', { variant: 'warning' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Desativa o botão de envio
        try {
            await apiAxios.post('/api/forms', form);
            enqueueSnackbar('Formulário criado com sucesso!', { variant: 'success' });
            onClose(); // Fecha o popup após a criação bem-sucedida
        } catch (error) {
            console.error('Erro ao criar formulário:', error);
            enqueueSnackbar('Erro ao criar o formulário.', { variant: 'error' });
        } finally {
            setIsSubmitting(false); // Reativa o botão de envio
        }
    };

    return (
        <div className={styles.popUp}>
            <div className={styles.content}>
                <h1>Criar Novo Formulário</h1>
                <form onSubmit={handleSubmit}>
                    <div className={styles.form}>
                        <h2>Informações básicas</h2>
                        <div className={styles.group}>
                            <label>Título:</label>
                            <input
                                type="text"
                                name="title"
                                value={form.title}
                                onChange={handleInputChange}
                                placeholder="Título do formulário"
                                required
                            />
                        </div>
                        <div className={styles.group}>
                            <label>Descrição:</label>
                            <input
                                type="text"
                                name="description"
                                value={form.description}
                                onChange={handleInputChange}
                                placeholder="Descrição do formulário"
                            />
                        </div>
                        <div className={styles.group}>
                            <label>Campanha Pública:</label>
                            <input
                                type="checkbox"
                                name="PublicCampaign"
                                checked={form.PublicCampaign}
                                onChange={e => setForm({ ...form, PublicCampaign: e.target.checked })}
                                style={{ width: "5%" }}
                            />
                        </div>
                        {form.PublicCampaign && (
                            <div className={styles.group}>
                                <label>Limite de Campanha:</label>
                                <input
                                    type="number"
                                    name="CampaignLimit"
                                    value={form.CampaignLimit}
                                    onChange={(e) => {
                                        const value = Math.max(0, Math.min(500, Number(e.target.value)));
                                        setForm(prevForm => ({
                                            ...prevForm,
                                            CampaignLimit: value
                                        }));
                                    }}
                                    placeholder="Limite de campanha"
                                    min="0"
                                    max="500"
                                />
                            </div>
                        )}
                        <h2>Adicionar Campos</h2>
                        <div className={styles.group}>
                            <label>Nome do Campo:</label>
                            <input
                                type="text"
                                name="name"
                                value={field.name}
                                onChange={handleFieldChange}
                                placeholder="Ex: Qual o seu nome ? | Qual o seu CPF ?"
                            />
                        </div>
                        <div className={styles.group}>
                            <label>Legenda do campo:</label>
                            <input
                                type="text"
                                name="placeholder"
                                value={field.placeholder}
                                onChange={handleFieldChange}
                                placeholder="Ex: nome | xxx.xxx.xxx.xx"
                            />
                        </div>
                        <div className={styles.group}>
                            <label>O campo é obrigatório?</label>
                            <input
                                type="checkbox"
                                name="required"
                                checked={field.required}
                                onChange={handleFieldChange}
                                style={{ width: "5%" }}
                            />
                        </div>
                        <div className={styles.groupCampo}>
                            <label>Pre-visualização:</label>
                            {form.fields.map((f, index) => (
                                <div key={index}>
                                    <p>{f.name}</p>
                                    <input placeholder={f.placeholder} disabled />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <button
                            style={{ background: "#c72a2f", color: "white" }}
                            type="submit"
                            disabled={isSubmitting} // Desativa o botão durante o envio
                        >
                            {isSubmitting ? 'Criando...' : 'Criar Formulário'} {/* Atualiza o texto do botão */}
                        </button>
                        <button type="button" onClick={addField}>Adicionar Campo</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateFormComponent;
