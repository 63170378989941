export function formatPhoneNumber(phoneNumber) {
    // Verifica se o número de telefone é indefinido, nulo ou vazio
    if (!phoneNumber) {
        return 'N/A'; // Retorna 'N/A' se o número de telefone não estiver presente
    }

    // Remove todos os caracteres que não sejam dígitos
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Verifica se o número de telefone possui 13 dígitos
    if (cleaned.length !== 13) {
        throw new Error('Número de telefone inválido. Deve ter exatamente 13 dígitos.');
    }

    // Formata o número de telefone como "+XX (XX) XXXXX-XXXX"
    const formatted = `+${cleaned.slice(0, 2)} (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 9)}-${cleaned.slice(9)}`;

    return formatted;
}
