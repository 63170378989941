import React, { useEffect, useState } from 'react';
import useTenantId from '../../tenantId';
import apiAxios from '../../../api/apiAxios';
import styles from './EditUserComponent.module.css';
import { series, turno } from '../../../DataSeries';
import Message from '../tools/Message';
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';
import { FaRegSave } from 'react-icons/fa';

const accessLabels = {
    isResponsavel: 'Responsável',
    isAluno: 'Aluno',
    coordenacao: 'Coordenação', // atualizado para coincidir com o objeto user
    nutricionista: 'Nutricionista', // atualizado para coincidir com o objeto user
    isProfessor: 'Professor',
    isSecretaria: 'Secretária'
};

const EditUserComponent = ({ user, AcessLevel = 'low' }) => {
    const tenantId = useTenantId();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        CPF: '',
        phoneNumber: '',
        access: '',
        alunoInfo: { serie: '', turno: '' },
        responsavelInfo: [],
        professorInfo: []
    });

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');

    useEffect(() => {
        if (user) {
            setFormData({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || '',
                CPF: user.CPF || '',
                phoneNumber: user.phoneNumber || '',
                access: determineAccess(user),
                alunoInfo: user.alunoInfo || { serie: '', turno: '' },
                responsavelInfo: user.responsavelInfo || [],
                professorInfo: user.professorInfo || []
            });
        }
    }, [user]);

    useEffect(() => {
        let timer;
        if (message) {
            timer = setTimeout(() => {
                setMessage('');
                setMessageType('');
            }, 20000); // 20 segundos
        }
        return () => clearTimeout(timer);
    }, [message]);

    const determineAccess = (user) => {
        if (user.isResponsavel) return 'isResponsavel';
        if (user.isAluno) return 'isAluno';
        if (user.coordenacao) return 'coordenacao'; // atualizado para coincidir com o objeto user
        if (user.nutricionista) return 'nutricionista'; // atualizado para coincidir com o objeto user
        if (user.isProfessor) return 'isProfessor';
        if (user.isSecretaria) return 'isSecretaria';
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'CPF') {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length <= 11) {
                setFormData({
                    ...formData,
                    [name]: digitsOnly
                });
            }
        } else if (name === 'phoneNumber') {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length <= 13) {
                setFormData({
                    ...formData,
                    [name]: digitsOnly
                });
            }
        } else if (name.startsWith('alunoInfo')) {
            const key = name.split('[')[1].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                alunoInfo: {
                    ...prevState.alunoInfo,
                    [key]: value
                }
            }));
        } else if (name.startsWith('responsavelInfo')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const key = name.split('[')[2].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                responsavelInfo: prevState.responsavelInfo.map((item, idx) =>
                    idx === index ? { ...item, [key]: value } : item
                )
            }));
        } else if (name.startsWith('professorInfo')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const key = name.split('[')[2].split(']')[0];
            setFormData(prevState => ({
                ...prevState,
                professorInfo: prevState.professorInfo.map((item, idx) =>
                    idx === index ? { ...item, [key]: value } : item
                )
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleAccessChange = (e) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            access: value,
            alunoInfo: value === 'isAluno' ? prevState.alunoInfo : { serie: '', turno: '' },
            responsavelInfo: value === 'isResponsavel' ? (prevState.responsavelInfo.length ? prevState.responsavelInfo : [{ nomeAluno: '', serie: '', turno: '', CPF: '' }]) : [],
            professorInfo: value === 'isProfessor' ? (prevState.professorInfo.length ? prevState.professorInfo : [{ serie: '', turno: '' }]) : []
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setMessage('');
        setMessageType('');

        let errorMessages = [];

        if (!formData.firstName) errorMessages.push('O nome é obrigatório.');
        if (!formData.lastName) errorMessages.push('O sobrenome é obrigatório.');
        if (!formData.access) errorMessages.push('O tipo de acesso é obrigatório.');

        if (formData.access !== 'isAluno') {
            if (!formData.CPF) {
                errorMessages.push('O CPF é obrigatório.');
            } else if (formData.CPF.length < 11) {
                errorMessages.push('CPF incompleto.');
            }
            if (!formData.phoneNumber) {
                errorMessages.push('O número de telefone é obrigatório.');
            } else if (formData.phoneNumber.length < 13) {
                errorMessages.push('Número de telefone incompleto.');
            }
        }

        if (errorMessages.length > 0) {
            setMessage(errorMessages.join(', '));
            setMessageType('error');
            return;
        }

        const { access, alunoInfo, responsavelInfo, professorInfo, ...basicData } = formData;

        const updatedUserData = {
            ...basicData,
            tenantId,
            access,
            alunoInfo: access === 'isAluno' ? alunoInfo : undefined,
            responsavelInfo: access === 'isResponsavel' ? responsavelInfo : undefined,
            professorInfo: access === 'isProfessor' ? professorInfo : undefined
        };

        try {
            await apiAxios.put(`/api/user/${tenantId}/edit/${user.id}`, updatedUserData);
            setMessage('Usuário atualizado com sucesso!');
            setMessageType('success');
        } catch (error) {
            setMessage('Erro ao atualizar usuário: ' + error.message);
            setMessageType('error');
        }
    };

    const addResponsavelInfo = () => {
        setFormData(prevState => ({
            ...prevState,
            responsavelInfo: [
                ...prevState.responsavelInfo,
                { nomeAluno: '', serie: '', turno: '', CPF: '' }
            ]
        }));
    };

    const removeResponsavelInfo = (index) => {
        setFormData(prevState => ({
            ...prevState,
            responsavelInfo: prevState.responsavelInfo.filter((_, idx) => idx !== index)
        }));
    };

    const addProfessorInfo = () => {
        setFormData(prevState => ({
            ...prevState,
            professorInfo: [
                ...prevState.professorInfo,
                { serie: '', turno: '' }
            ]
        }));
    };

    const removeProfessorInfo = (index) => {
        setFormData(prevState => ({
            ...prevState,
            professorInfo: prevState.professorInfo.filter((_, idx) => idx !== index)
        }));
    };

    const accessibleTypes = AcessLevel === 'high'
        ? ['isAluno', 'isProfessor', 'isResponsavel', 'coordenacao', 'nutricionista', 'isSecretaria']
        : ['isAluno', 'isProfessor'];

    if (formData.access && !accessibleTypes.includes(formData.access)) {
        accessibleTypes.push(formData.access);
    }

    return (
        <>
            {message && (
                <Message type={messageType} message={message} />
            )}
            <form onSubmit={handleSubmit} className={styles.form} noValidate>
                <div className={styles.layout}>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder='Nome'
                    />
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder='Sobrenome'
                    />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder='Email'
                    />
                    <input
                        type="text"
                        name="CPF"
                        value={formData.CPF}
                        onChange={handleChange}
                        placeholder='CPF'
                    />
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder='Número de Telefone'
                    />

                    <p>Tipo de Acesso:</p>
                    <div className={styles.checkboxGroup}>
                        {Object.keys(accessLabels).map(accessType => (
                            accessibleTypes.includes(accessType) && (
                                <label key={accessType} className={styles.checkboxLabel}>
                                    <input
                                        type="radio"
                                        name="access"
                                        value={accessType}
                                        checked={formData.access === accessType}
                                        onChange={handleAccessChange}
                                    />
                                    {accessLabels[accessType]}
                                </label>
                            )
                        ))}
                    </div>

                    {formData.access === 'isAluno' && (
                        <div>
                            <p>Informações do Aluno:</p>
                            <select
                                name="alunoInfo[serie]"
                                value={formData.alunoInfo.serie}
                                onChange={handleChange}
                            >
                                <option value="">Selecione a Série</option>
                                {series.map((serie) => (
                                    <option key={serie} value={serie}>{serie}</option>
                                ))}
                            </select>
                            <select
                                name="alunoInfo[turno]"
                                value={formData.alunoInfo.turno}
                                onChange={handleChange}
                            >
                                <option value="">Selecione o Turno</option>
                                {turno.map((turnoOption) => (
                                    <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                ))}
                            </select>
                        </div>
                    )}

                    {formData.access === 'isProfessor' && (
                        <div>
                            <p>Informações do Professor:</p>
                            {formData.professorInfo.map((info, index) => (
                                <div key={index} className={styles.turmaContainer}>
                                    <p className={styles.turmaTitle}>
                                        {info.serie && info.turno ? `${info.serie} - ${info.turno}` : `Turma ${index + 1}`}
                                    </p>

                                    <div className={styles.turmaFields}>
                                        <select
                                            name={`professorInfo[${index}][serie]`}
                                            value={info.serie}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione a Série</option>
                                            {series.map((serie) => (
                                                <option key={serie} value={serie}>{serie}</option>
                                            ))}
                                        </select>
                                        <select
                                            name={`professorInfo[${index}][turno]`}
                                            value={info.turno}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione o Turno</option>
                                            {turno.map((turnoOption) => (
                                                <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                            ))}
                                        </select>
                                        <button type="button" onClick={() => removeProfessorInfo(index)} className={styles.removeButton}>
                                            <IoMdRemoveCircleOutline /> Remover Turma
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button type="button" onClick={addProfessorInfo} className={styles.addButton}>
                                <IoIosAddCircleOutline /> Adicionar Turma
                            </button>
                        </div>
                    )}

                    {formData.access === 'isResponsavel' && (
                        <div>
                            <p>Informações do Responsável:</p>
                            {formData.responsavelInfo.map((info, index) => (
                                <div key={index} className={styles.alunoContainer}>
                                    <p className={styles.alunoTitle}>
                                        {info.nomeAluno || `Aluno ${index + 1}`}
                                    </p>

                                    <div className={styles.alunoFields}>
                                        <input
                                            type="text"
                                            name={`responsavelInfo[${index}][nomeAluno]`}
                                            value={info.nomeAluno}
                                            onChange={handleChange}
                                            placeholder='Nome do Aluno'
                                        />
                                        <select
                                            name={`responsavelInfo[${index}][serie]`}
                                            value={info.serie}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione a Série</option>
                                            {series.map((serie) => (
                                                <option key={serie} value={serie}>{serie}</option>
                                            ))}
                                        </select>
                                        <select
                                            name={`responsavelInfo[${index}][turno]`}
                                            value={info.turno}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione o Turno</option>
                                            {turno.map((turnoOption) => (
                                                <option key={turnoOption} value={turnoOption}>{turnoOption}</option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            name={`responsavelInfo[${index}][CPF]`}
                                            value={info.CPF}
                                            onChange={handleChange}
                                            placeholder='CPF do Aluno'
                                        />
                                        <button type="button" onClick={() => removeResponsavelInfo(index)} className={styles.removeButton}>
                                            <IoMdRemoveCircleOutline /> Remover Aluno
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button type="button" onClick={addResponsavelInfo} className={styles.addButton}>
                                <IoIosAddCircleOutline /> Adicionar Aluno
                            </button>
                        </div>
                    )}

                    <div className={styles.layout2}>
                        <button type="submit" className={styles.saveButton}>
                            <FaRegSave /> Atualizar Usuário
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditUserComponent;
