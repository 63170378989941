import React, { useState, useEffect } from 'react';
import styles from './AgendaAdminCreate.module.css';
import { MdKeyboardArrowLeft, MdOutlineClass, MdOutlineSpaceDashboard } from "react-icons/md";
import { LuApple } from "react-icons/lu";
import { MdCalendarToday } from 'react-icons/md';
import InterfaceStructure from '../../../components/InterfaceStructure';
import { Link, useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { RiDeleteBinLine } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import { series } from "../../../DataSeries";
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const AgendaAdminCreate = () => {
    const [titulo, setTitulo] = useState('');
    const [materia, setMateria] = useState([]);
    const [serie, setSerie] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const [materiasCarregadas, setMateriasCarregadas] = useState(true);
    const [isDateAvailable, setIsDateAvailable] = useState(true);
    const [initialMateria, setInitialMateria] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const itemsPagesList = itemsPagesWithExclusions('Agenda', []);

    useEffect(() => {
        const fetchMaterias = async () => {
            try {
                if (serie) {
                    const response = await apiAxios.get(`/api/mat/materias?serie=${serie}&tenantId=${tenantId}`);
                    const materiasDaSerie = response.data;
                    console.log(materiasDaSerie);

                    const materiasFiltradas = materiasDaSerie.filter(mat => mat.serie === serie);

                    if (materiasFiltradas.length === 0) {
                        setMateriasCarregadas(false);
                        setMateria([]);
                        setInitialMateria([]);
                    } else {
                        const materiasFormatadas = materiasFiltradas.map(mat => ({
                            ...mat,
                            materia: mat.materia.map(item => ({
                                ...item,
                                objetivoConhecimento: '',
                                habilidades: '',
                                metodologia: '',
                                material: '',
                            }))
                        }));

                        setMateria(materiasFormatadas);
                        setInitialMateria(JSON.parse(JSON.stringify(materiasFormatadas))); // Salvar estado inicial das matérias
                        setMateriasCarregadas(true);
                    }
                } else {
                    setMateria([]);
                    setInitialMateria([]);
                    setMateriasCarregadas(true);
                }
            } catch (error) {
                console.error('Erro ao buscar matérias:', error);
                setMateriasCarregadas(false);
            }
        };

        fetchMaterias();
    }, [serie]);

    const handleSerieChange = (e) => {
        const selectedSerie = e.target.value;
        setSerie(selectedSerie);
        setMateria([]);
    };

    const handleMouseEnter = (index, matIndex) => {
        setHoveredIndex({ index, matIndex });
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleMateriaChange = (index, matIndex, field, value) => {
        const updatedMateria = [...materia];
        updatedMateria[index].materia[matIndex] = {
            ...updatedMateria[index].materia[matIndex],
            [field]: value
        };
        setMateria(updatedMateria);
    };

    const handleRemoveMateria = (index, matIndex) => {
        const updatedMateria = [...materia];
        updatedMateria[index].materia.splice(matIndex, 1);
        if (updatedMateria[index].materia.length === 0) {
            updatedMateria.splice(index, 1);
        }
        setMateria(updatedMateria);
    };

    const handleUndo = () => {
        setMateria(JSON.parse(JSON.stringify(initialMateria)));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");

        const isDateAlreadyUsed = await checkDateAvailability(formattedDate);
        if (!isDateAlreadyUsed) {
            setIsDateAvailable(false);
            return;
        }

        const materiaSimplificada = materia.flatMap(item => (
            item.materia.map(mat => ({
                ...item,
                materia: mat.materia,
                color: mat.color,
                description: mat.description,
                objetivoConhecimento: mat.objetivoConhecimento || '',
                habilidades: mat.habilidades || '',
                metodologia: mat.metodologia || '',
                material: mat.material || '',
            }))
        ));

        const novaAgenda = {
            tenantId: tenantId,
            date: formattedDate,
            serie: serie,
            materia: materiaSimplificada,
            agendastatus: true,
        };

        try {
            const response = await apiAxios.post('/api/agenda/', novaAgenda);
            console.log(response);
            navigate('/agenda');
        } catch (error) {
            console.error('Erro ao criar agenda:', error);
        }
    };

    const checkDateAvailability = async (date) => {
        try {
            const response = await apiAxios.get('/api/agenda');
            const formattedDate = moment(date, "DD/MM/YYYY HH:mm:ss").toISOString().split('T')[0];
            return !response.data.some(event => {
                const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).toISOString().split('T')[0];
                return eventDate === formattedDate;
            });
        } catch (error) {
            console.error('Erro ao verificar disponibilidade da data:', error);
            return false;
        }
    };

    const renderMaterias = () => {
        if (!materiasCarregadas) {
            return <p>Nenhuma matéria encontrada para a série selecionada.</p>;
        }

        return materia.map((item, index) => (
            <div key={index} className={styles.materiaBlock}>
                {item.materia.map((mat, matIndex) => (
                    <div key={`${index}-${matIndex}`} className={styles.materiaItem}>
                        <div className={styles.materiaItemHeader}>
                            <h2>{mat.materia}</h2>
                            <button
                                onClick={() => handleRemoveMateria(index, matIndex)}
                                className={styles.removeButton}
                                onMouseEnter={() => handleMouseEnter(index, matIndex)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {hoveredIndex && hoveredIndex.index === index && hoveredIndex.matIndex === matIndex
                                    ? <RiDeleteBin5Line />
                                    : <RiDeleteBinLine />}
                            </button>
                        </div>
                        <input
                            type="text"
                            placeholder="Objetivo de Conhecimento"
                            value={mat.objetivoConhecimento}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'objetivoConhecimento', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Habilidades"
                            value={mat.habilidades}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'habilidades', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Metodologia"
                            value={mat.metodologia}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'metodologia', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Material"
                            value={mat.material}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'material', e.target.value)}
                        />
                    </div>
                ))}
            </div>
        ));
    };

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/agenda'><MdKeyboardArrowLeft />Agenda</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Criar Agenda</h1>
                        <p>Administrador</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.BasicInfoForm}>
                        <p>Informações Básicas da Agenda</p>
                        <input
                            type="text"
                            placeholder="Título da Agenda"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            required
                        />
                        <select
                            value={serie}
                            onChange={handleSerieChange}
                            className={styles.select}
                            required
                        >
                            <option value="" disabled>Selecione a Série</option>
                            {series.map((s, index) => (
                                <option key={index} value={s}>{s}</option>
                            ))}
                        </select>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="dd/MM/yyyy HH:mm"
                            placeholderText="Selecione a data e hora"
                            className={styles.datePicker}
                            required
                        />
                        {!isDateAvailable && <p className={styles.error}>*Já existe uma agenda para essa data.</p>}
                        {renderMaterias()}
                        <div className={styles.botoesDeControle}>
                            <button type="button" onClick={handleUndo} className={styles.undoButton}>Desfazer</button>
                            <button type="submit">Criar Agenda</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <div>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
        </div>
    );
}

export default AgendaAdminCreate;
