import React from 'react';
import styles from './Message.module.css';

const Message = ({ type = 'success', message }) => {
  const icon = {
    success: '✅',
    error: '❌',
    warning: '⚠️'
  }[type];

  return (
    <div className={`${styles.message} ${styles[type]}`}>
      <span className={styles.icon}>{icon}</span>
      {Array.isArray(message) ? (
        <ul className={styles.messageList}>
          {message.map((msg, index) => (
            <li key={index} className={styles.messageItem}>{msg}</li>
          ))}
        </ul>
      ) : (
        <span>{message}</span>
      )}
    </div>
  );
};

export default Message;
