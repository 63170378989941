import React, { useState } from 'react';
import styles from './SendEmailChangePass.module.css';
import img from '../assets/Design sem nome (1).png';
import { IoMdClose } from "react-icons/io";
import apiAxios from '../api/apiAxios';

const SendEmailChangePass = ({ CloseModal }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false); // Novo estado para sucesso

    const handleSendEmail = async () => {
        setLoading(true);
        setError('');
        setSuccess(false); // Resetar o estado de sucesso antes de enviar

        try {
            const response = await apiAxios.post('/api/user/forgot-password', { email });

            if (response.status === 200) {
                setSuccess(true); // Atualizar o estado de sucesso
                console.log('Email para recuperação de senha enviado para:', email);
            } else {
                setError(response.data.message || 'Erro ao enviar e-mail de recuperação.');
            }
        } catch (error) {
            setError('Erro ao enviar e-mail de recuperação. Tente novamente com um e-mail existente.');
        } finally {
            setLoading(false);
        }
    };

    const handleModalClick = (e) => {
        if (e.target === e.currentTarget) {
            CloseModal();
        }
    };

    const isEmailValid = email.trim() !== '';

    return (
        <div onClick={handleModalClick} className={styles.modal}>
            <div className={`${styles.content} ${styles.animate}`}>
                <div onClick={CloseModal} className={styles.closeButton}>
                    <button onClick={CloseModal}><IoMdClose /></button>
                </div>
                <div className={styles.form}>
                    {success ? (
                        <div className={styles.successMessage}>
                            <h2>Email enviado🎊</h2>
                            <p>O email foi enviado para <strong>{email}</strong> com um link para definir nova senha.</p>
                            <p>Caso não tenha recebido, verifique a pasta <strong>Lixo Eletrônico/Spam.</strong></p>
                        </div>
                    ) : (
                        <>
                            <div className={styles.send}>
                                <h2>Nova Senha</h2>
                                <p>Enviaremos as instruções por e-mail para você definir uma nova senha.</p>
                                <div className={styles.email}>
                                    <label>E-mail</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={isEmailValid ? styles.inputFilled : ''}
                                    />
                                </div>
                                <div className={styles.error}>
                                    {error && <p>*{error}</p>}
                                </div>
                                <button
                                    onClick={handleSendEmail}
                                    disabled={!isEmailValid || loading}
                                    className={isEmailValid ? styles.buttonEnabled : styles.buttonDisabled}
                                >
                                    {loading ? 'Enviando...' : 'Enviar E-mail'}
                                </button>
                            </div>
                            <div className={styles.img}>
                                <img src={img} alt='img' />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SendEmailChangePass;
