import React, { useEffect, useState } from 'react';
import styles from './RequestsCoordenacao.module.css';
import InterfaceStructure from '../../components/InterfaceStructure';
import { MdKeyboardArrowLeft} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../components/FormatNumber';
import { deleteRequestById, getAllRequests } from '../../actions/RequestActions';
import { FcApprove, FcDisapprove } from "react-icons/fc";
import { approveRequest } from '../../actions/UserActions';
import { IoMdTime } from 'react-icons/io';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';

const RequestsCoordenacao= () => {
  const itemsPagesList = itemsPagesWithExclusions('Painel', ['Agenda','Informes','Atividades','Chat','Fichas','Cardápio']);


  const [data, setData] = useState([]);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = await getAllRequests(tokenPayload.tenantId);
        const mappedRequests = requests.map(request => ({
          id: request._id,
          Number: formatPhoneNumber(request.phoneNumber),
          user: request.firstName,
          usersecond: request.lastName,
          email: request.email,
          admin: request.isAdmin,
          emailVerified: request.emailVerified
        }));
        setData(mappedRequests);
      } catch (error) {
        console.error("Erro ao obter solicitações:", error);
      }
    };
    fetchData();
  }, [tokenPayload.tenantId]);

  const handleApproveRequest = async (requestId) => {
    try {
      await approveRequest(requestId);
      setData(prevData => prevData.filter(request => request.id !== requestId));
    } catch (error) {
      console.error("Erro ao aprovar solicitação:", error);
    }
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      await deleteRequestById(requestId);
      setData(prevData => prevData.filter(request => request.id !== requestId));
    } catch (error) {
      console.error("Erro ao excluir solicitação:", error);
    }
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Ação',
      width: 300,
      renderCell: (params) => (
        params.row.emailVerified ? (
          <>
            <FcApprove
              className={styles.Aprove}
              onClick={() => handleApproveRequest(params.row.id)}
            />
            <FcDisapprove
              className={styles.Reprove}
              onClick={() => handleDeleteRequest(params.row.id)}
            />
          </>
        ) : (
          <div className={styles.timeVerify}>
            <IoMdTime style={{ fontSize: "20px " }} />Esperando Verificar Email.
          </div>
        )
      ),
    },
    { field: 'admin', headerName: 'Gestor', width: 180 },
    { field: 'user', headerName: 'Nome', width: 200 },
    { field: 'usersecond', headerName: 'Sobrenome', width: 200 },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'Number', headerName: 'Número', width: 180 },
  ];

  const dashboardContent = (
    <div className={styles.all}>
      <div className={styles.home}>
        <div className={styles.returnPainelArrow}>
          <Link to='/'><MdKeyboardArrowLeft />Painel</Link>
        </div>
        <div className={styles.topAreaInfo}>
          <div className={styles.start}>
            <h1>Solicitações de cadastro</h1>
            <p>Secretaria</p>
          </div>
          <div className={styles.end}>
            ...
          </div>
        </div>
        <div className={styles.userList}>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={8}
            disableSelectionOnClick
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );

  return (
    <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
  );
};

export default RequestsCoordenacao;
