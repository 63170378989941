import React from 'react';
import styles from './MateriasCreateComponent.module.css';
import { series } from '../../../DataSeries';

const MateriasCreateComponent = ({
    formData,
    setFormData,
    handleFormSubmit,
    handleAddMateria,
    handleCloseModal,
    selectedSerie,
    setSelectedSerie,
    seriesDisponiveis
}) => {
    const handleInputChange = (e, index) => {
        try {
            if (e && e.target && e.target instanceof HTMLInputElement) { // Verifica se o target é um elemento de input válido
                const { name, value } = e.target;
                if (name) { // Certifica que 'name' está definido
                    const updatedMaterias = [...formData.materias];
                    updatedMaterias[index] = { ...updatedMaterias[index], [name]: value };
                    setFormData({ ...formData, materias: updatedMaterias });
                } else {
                    console.warn('O evento de entrada não possui um atributo name válido:', e);
                }
            } else {
                console.error('Erro: Evento de entrada inválido ou elemento target não é um input.', e);
            }
        } catch (error) {
            console.error('Erro ao processar o evento de entrada:', error);
        }
    };

    // Função para remover uma matéria
    const handleRemoveMateria = (indexToRemove) => {
        try {
            const updatedMaterias = formData.materias.filter((_, index) => index !== indexToRemove);
            setFormData({ ...formData, materias: updatedMaterias });
        } catch (error) {
            console.error('Erro ao remover matéria:', error);
        }
    };

    return (
        <form className={styles.form} onSubmit={handleFormSubmit}>
            {formData.materias.map((materia, index) => (
                <div className={styles.materiaContainer} key={index}>
                    <div className={styles.formGroup}>
                        <label htmlFor={`materia-${index}`}>Nome da matéria:</label>
                        <input
                            id={`materia-${index}`}
                            type="text"
                            name="materia"
                            value={materia.materia || ''}
                            onChange={(e) => handleInputChange(e, index)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor={`description-${index}`}>Descrição:</label>
                        <input
                            id={`description-${index}`}
                            type="text"
                            name="description" 
                            value={materia.description || ''} 
                            onChange={(e) => handleInputChange(e, index)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor={`color-${index}`}>Cor:</label>
                        <input
                            id={`color-${index}`}
                            type="color"
                            name="color" 
                            className={styles.colorInput}
                            value={materia.color || '#000000'} 
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    </div>
                    <button
                        type="button"
                        className={styles.removeButton}
                        onClick={() => handleRemoveMateria(index)}
                    >
                        Excluir matéria
                    </button>
                </div>
            ))}
            <div className={styles.formGroup}>
                <label htmlFor="serie">Série:</label>
                <select
                    id="serie"
                    name="serie"
                    value={selectedSerie || ''} 
                    onChange={(e) => setSelectedSerie(e.target.value)}
                    required
                >
                    <option value="" disabled>Selecione uma série</option>
                    {console.log(seriesDisponiveis)}
                    {seriesDisponiveis.length > 0 ? (
                        seriesDisponiveis.map((serie, index) => (
                            <option key={index} value={serie}>
                                {serie}
                            </option>
                        ))
                    ) : (
                        <option value="" disabled>Não há séries disponíveis</option>
                    )}
                </select>
            </div>
            <div className={styles.buttonContainer}>
                <button type="submit" className={styles.submitButton}>Enviar</button>
                <button type="button" className={styles.secondaryButton} onClick={handleAddMateria}>
                    Adicionar outra matéria
                </button>
                <button type="button" className={styles.cancelButton} onClick={handleCloseModal}>
                    Cancelar
                </button>
            </div>
        </form>
    );
};

export default MateriasCreateComponent;
