import React from 'react';
import InterfaceStructure from '../../../components/InterfaceStructure';
import useTenantId from '../../../components/tenantId';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import FichasComponent from '../../../components/fichasComponent/FichasComponent';

const FichasSecretariaPage = () => {
    const tenantId = useTenantId();  // Obtendo tenantId
    const itemsPagesList = itemsPagesWithExclusions('Fichas');

    return (
        <FichasComponent PagesList={itemsPagesList} tenantId={tenantId} />
    );
};

export default FichasSecretariaPage;