import React, { useState, useEffect, useRef } from 'react';
import styles from './CategoriaCardapioCreateComponent.module.css';
import apiAxios from '../../../../api/apiAxios';
import { IoIosArrowUp } from "react-icons/io";

// Função para converter cor hexadecimal para RGBA com transparência
const hexToRgba = (hex, alpha = 0.5) => {
  let r = 0, g = 0, b = 0;
  // Caso a cor hexadecimal seja de 3 dígitos
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) { // Caso a cor seja de 6 dígitos
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const CategoriaCardapioCreateComponent = ({ onClose, tenantId }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [newCategory, setNewCategory] = useState({ 
    title: '',
    description: '',
    color: '#000000',  // Cor padrão
  });
  const [categories, setCategories] = useState([]);

  const popupRef = useRef(null);
  const openButtonRef = useRef(null);

  // Buscar categorias ao montar o componente
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiAxios.get(
          `/api/card/cat/groups/${tenantId}`
        );
        const categoriesData = response.data;
        setCategories(categoriesData);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    fetchCategories();
  }, [tenantId]);

  // Listener para cliques fora do popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        openButtonRef.current &&
        !openButtonRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    if (isPopupOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Limpar o listener quando o componente for desmontado
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isPopupOpen]);

  // Foco no popup quando aberto
  useEffect(() => {
    if (isPopupOpen) {
      popupRef.current.focus();
    } else if (openButtonRef.current) {
      openButtonRef.current.focus();
    }
  }, [isPopupOpen]);

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prevState) => ({ ...prevState, [name]: value }));
  };

  const addCategory = async () => {
    try {
      const response = await apiAxios.post(
        `/api/card/cat/groups/${tenantId}`,
        {
          Titulo: newCategory.title,
          Descrição: newCategory.description,
          Color: hexToRgba(newCategory.color, 0.5), // Convertendo a cor para RGBA com transparência
        }
      );
      setCategories([...categories, response.data]);
      setNewCategory({ title: '', description: '', color: '#000000' });
    } catch (error) {
      console.error('Erro ao adicionar categoria:', error);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsPopupOpen(false);
      setIsClosing(false);
      if (onClose) onClose();
    }, 300);
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    handleClose();
  };

  const togglePopup = (event) => {
    event.stopPropagation();
    if (isPopupOpen) {
      handleClose();
    } else {
      setIsPopupOpen(true);
    }
  };

  return (
    <>
      <div className={styles.return}>
        <button
          className={`${styles.closeIcon} ${isClosing ? styles.flipDown : ''}`}
          onClick={handleCloseClick}
          aria-label="Fechar Popup"
        >
          <IoIosArrowUp />
        </button>
      </div>
      <h2>Adicionar Categoria</h2>
      <div className={styles.formGroup}>
        <input
          type="text"
          name="title"
          placeholder="Nome da Categoria"
          value={newCategory.title}
          onChange={handleCategoryChange}
        />
        <input
          type="text"
          name="description"
          placeholder="Descrição da Categoria"
          value={newCategory.description}
          onChange={handleCategoryChange}
        />
        <input
          type="color"
          name="color"
          value={newCategory.color}
          onChange={handleCategoryChange}
        />
        <button className={styles.addButton} onClick={addCategory}>
          Adicionar Categoria
        </button>
      </div>
    </>
  );
};

export default CategoriaCardapioCreateComponent;
