import React from 'react'
import styles from './NotFoundPage.module.css';
import { TbError404 } from "react-icons/tb";

const NotFoundPage = () => {
    return (
        <div className={styles.notfound}>
            <div>
                <TbError404 />
                <p>Not Found</p>
            </div>
        </div>
    )
}

export default NotFoundPage