import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import CardapioComponentCreate from '../../../components/pagesComponents/CardapioComponents/CardapioComponentCreate';

const CardapioPageCreate = () => {
  const pagesList = itemsPagesWithExclusions('Cardápio',[]);
  return (
    <>
      <CardapioComponentCreate pagesList={pagesList} />
    </>
  );
};

export default CardapioPageCreate;
