import React, { useEffect, useState, useCallback } from 'react';
import styles from './PlanosComponentEdit.module.css';
import apiAxios from '../../../api/apiAxios';
import { series, turno as turnos } from '../../../DataSeries';
import useTenantId from '../../tenantId';
import { FaRegSave } from "react-icons/fa";
import { debounce } from 'lodash';
import Message from '../tools/Message';
import { useSnackbar } from 'notistack';

const MESES_DO_ANO = [
    { mes: "Janeiro" },
    { mes: "Fevereiro" },
    { mes: "Março" },
    { mes: "Abril" },
    { mes: "Maio" },
    { mes: "Junho" },
    { mes: "Julho" },
    { mes: "Agosto" },
    { mes: "Setembro" },
    { mes: "Outubro" },
    { mes: "Novembro" },
    { mes: "Dezembro" },
];

const PlanosComponentEdit = ({ plan, onSave, onClose, accessLevel }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [serie, setSerie] = useState('');
    const [turnoSelecionado, setTurnoSelecionado] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear().toString());
    const [materias, setMaterias] = useState([]);
    const [meses, setMeses] = useState(MESES_DO_ANO);
    const [isLoading, setIsLoading] = useState(true);
    const [availableTurnos, setAvailableTurnos] = useState(turnos);
    const tenantId = useTenantId();
    const [message, setMessage] = useState(null);

    const autoSave = useCallback(
        debounce(async (updatedPlano) => {
            try {
                await apiAxios.put(`/api/planos/planos/${plan._id}`, updatedPlano);
                setMessage({ type: 'success', message: 'Plano salvo automaticamente!' });
            } catch (error) {
                setMessage({ type: 'error', message: 'Erro ao salvar automaticamente o plano' });
            }
        }, 1000),
        [plan._id]
    );

    const handleChange = (field, value) => {
        // Atualize o campo correspondente com o novo valor
        if (field === 'titulo') setTitulo(value);
        if (field === 'descricao') setDescricao(value);
        if (field === 'serie') setSerie(value);
        if (field === 'turnoSelecionado') setTurnoSelecionado(value);
        if (field === 'ano') setAno(value);

        // Crie o objeto atualizado
        const updatedPlano = {
            tenantId,
            titulo: field === 'titulo' ? value : titulo,
            descricao: field === 'descricao' ? value : descricao,
            serie: field === 'serie' ? value : serie,
            turno: field === 'turnoSelecionado' ? value : turnoSelecionado,
            ano: field === 'ano' ? value : ano,
            planejamento: meses,
            _id: plan._id,
        };

        autoSave(updatedPlano);
    };

    useEffect(() => {
        if (plan) {
            setTitulo(plan.titulo);
            setDescricao(plan.descricao);
            setSerie(plan.serie);
            setTurnoSelecionado(plan.turno);
            setAno(plan.ano || new Date().getFullYear().toString());

            const updatedMeses = MESES_DO_ANO.map((mes) => ({
                ...mes,
                plano: plan.planejamento.find((p) => p.mes === mes.mes)?.plano || [],
            }));
            setMeses(updatedMeses);
        }
    }, [plan]);

    useEffect(() => {
        const fetchPlanosAndFilterTurnos = async () => {
            if (tenantId && serie) {
                try {
                    const response = await apiAxios.get(`/api/planos/planos?tenantId=${tenantId}&serie=${serie}`);
                    const planosExistentes = response.data;

                    const turnosOcupados = planosExistentes
                        .filter(p => p._id !== plan._id)
                        .map(p => p.turno);

                    const turnosDisponiveis = turnos.filter(t => t === plan.turno || !turnosOcupados.includes(t));
                    setAvailableTurnos(turnosDisponiveis);
                } catch (error) {
                    setMessage({ type: 'error', message: 'Erro ao buscar planos existentes' });
                }
            }
        };

        fetchPlanosAndFilterTurnos();
    }, [tenantId, serie, plan]);

    useEffect(() => {
        const fetchMaterias = async () => {
            if (tenantId && serie) {
                try {
                    const response = await apiAxios.get(`/api/mat/materias?tenantId=${tenantId}&serie=${serie}`);
                    const fetchedMaterias = response.data.flatMap(materia =>
                        materia.materia.map(m => ({ id: m._id, materia: m.materia }))
                    );
                    setMaterias(fetchedMaterias);

                    const updatedMeses = meses.map((mes) => {
                        const updatedPlano = fetchedMaterias.map(fetchedMateria => {
                            const existingMateria = mes.plano.find(p => p.materia === fetchedMateria.materia);
                            return {
                                id: fetchedMateria.id,
                                materia: fetchedMateria.materia,
                                descricao: existingMateria ? existingMateria.descricao : ''
                            };
                        });
                        return {
                            ...mes,
                            plano: updatedPlano
                        };
                    });
                    setMeses(updatedMeses);

                } catch (error) {
                    setMessage({ type: 'error', message: 'Erro ao buscar matérias' });
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchMaterias();
    }, [tenantId, serie]);

    const handleChangePlanejamento = (mesIndex, planoIndex, field, value) => {
        const newMeses = [...meses];
        newMeses[mesIndex].plano[planoIndex][field] = value;
        setMeses(newMeses);

        const updatedPlano = {
            tenantId,
            titulo,
            descricao,
            planejamento: newMeses,
            serie,
            turno: turnoSelecionado,
            ano,
            _id: plan._id,
        };
        autoSave(updatedPlano);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedPlano = {
            tenantId,
            titulo,
            descricao,
            planejamento: meses,
            serie,
            turno: turnoSelecionado,
            ano,
            _id: plan._id,
        };

        try {
            await apiAxios.put(`/api/planos/planos/${plan._id}`, updatedPlano);
            setMessage({ type: 'success', message: 'Plano atualizado com sucesso!' });
            onSave(updatedPlano);
        } catch (error) {
            setMessage({ type: 'error', message: 'Erro ao atualizar o plano' });
        }
    };

    return (
        <div className={styles.formContainer}>
            {message && <Message type={message.type} message={message.message} />}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.layout}>
                    <div className={styles.formGroup}>
                        <p>Editar informações do plano</p>
                        <input
                            placeholder='Título'
                            type="text"
                            value={titulo}
                            onChange={(e) => handleChange('titulo', e.target.value)}
                            maxLength={100}
                            required
                        />
                        <textarea
                            placeholder='Descrição'
                            value={descricao}
                            onChange={(e) => handleChange('descricao', e.target.value)}
                            maxLength={500}
                            required
                        />

                        {accessLevel === 'high' && (
                            <select value={serie} onChange={(e) => handleChange('serie', e.target.value)} required>
                                <option value="">Selecione a série</option>
                                {series.map((serieOption, index) => (
                                    <option key={index} value={serieOption}>{serieOption}</option>
                                ))}
                            </select>
                        )}

                        {accessLevel === 'low' && (
                            <input type="text" value={serie} readOnly disabled />
                        )}

                        <select value={turnoSelecionado} onChange={(e) => handleChange('turnoSelecionado', e.target.value)} required>
                            <option value="">Selecione o turno</option>
                            {availableTurnos.map((turnoOption, index) => (
                                <option key={index} value={turnoOption}>{turnoOption}</option>
                            ))}
                        </select>

                        <select value={ano} onChange={(e) => handleChange('ano', e.target.value)} required>
                            <option value={ano}>{ano}</option>
                        </select>

                        {isLoading ? (
                            <div>Carregando...</div>
                        ) : (
                            materias.length === 0 ? (
                                <div>Não há matérias disponíveis para a série e turno selecionados.</div>
                            ) : (
                                meses.map((mesItem, mesIndex) => (
                                    <div key={mesIndex} className={styles.planejamentoGroup}>
                                        <h3>Planejamento do mês: {mesItem.mes}</h3>
                                        {mesItem.plano.map((planoItem, planoIndex) => (
                                            <div key={planoIndex}>
                                                <input
                                                    type="text"
                                                    value={planoItem.materia}
                                                    readOnly
                                                    disabled
                                                />
                                                <textarea
                                                    value={planoItem.descricao}
                                                    onChange={(e) => handleChangePlanejamento(mesIndex, planoIndex, 'descricao', e.target.value)}
                                                    maxLength={500}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))
                            )
                        )}
                    </div>
                    <div className={styles.controlButton}>
                        <button type="submit" className={styles.submitButton}>
                            <FaRegSave />&nbsp;Atualizar Plano
                        </button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PlanosComponentEdit;
