// src/pages/FichasPage.js

import React from 'react';
import styles from './FichasPage.module.css';
import InterfaceStructure from '../../../components/InterfaceStructure';
import useTenantId from '../../../components/tenantId';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import FichasComponent from '../../../components/fichasComponent/FichasComponent';

const FichasPage = () => {
    const tenantId = useTenantId();  // Obtendo tenantId
    const itemsPagesList = itemsPagesWithExclusions('Fichas', []);

    return (
        <FichasComponent PagesList={itemsPagesList} tenantId={tenantId} />
    );
};

export default FichasPage;
