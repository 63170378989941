import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import AgendaComponent from '../../../components/pagesComponents/AgendaComponents/AgendaComponent';

const AgendaAdminPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Agenda', []);

    return (
        <AgendaComponent PagesList={itemsPagesList}/>
    );
};

export default AgendaAdminPage;
