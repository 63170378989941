import React, { useEffect, useRef } from 'react';
import styles from './MiniPopUpComponent.module.css';
import { useSnackbar } from 'notistack';

const MiniPopUpComponent = ({ title, message, onConfirm, onCancel }) => {
    const popupRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar(); // Usando o notistack para notificações

    // Função para detectar clique fora do popup
    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            onCancel(); // Fecha o modal, mas não confirma a ação
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Função para confirmar e mostrar a notificação de sucesso
    const handleConfirm = () => {
        onConfirm(); // Executa a função de confirmação passada
        enqueueSnackbar('Ação confirmada com sucesso!', { variant: 'success' }); // Exibe mensagem de sucesso
    };

    return (
        <div className={styles.popupOverlay}>
            <div ref={popupRef} className={styles.popup}>
                <div className={styles.title}>
                    <h2>{title}</h2>
                    <p>{message}</p>
                </div>
                <div className={styles.popupActions}>
                    <button className={styles.cancelButton} onClick={onCancel}>
                        Cancelar
                    </button>
                    <button className={styles.confirmButton} onClick={handleConfirm}>
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MiniPopUpComponent;
