import styles from './CreateHorarioComponent.module.css'
import useTenantId from '../../tenantId';
import apiAxios from '../../../api/apiAxios';
import { series, turno } from "../../../DataSeries";
import { MdDeleteOutline } from "react-icons/md";
import { IoClose } from 'react-icons/io5';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

const EditHorarioComponent = ({ horarioId, onHorarioUpdated }) => {
    console.log(horarioId)
    const { enqueueSnackbar } = useSnackbar();

    const [horario, setHorario] = useState({
        serie: '',
        turno: '',
        week: [{
            week_day: 'Segunda-feira',
            TimeStart: '',
            TimeEnd: '',
            public: false,
            materias: [{ TimeStart: '', TimeEnd: '', title: '', conteudo: '' }]
        }]
    });

    const tenantId = useTenantId();

    useEffect(() => {
        if (horarioId) {
            const fetchHorario = async () => {
                try {
                    const response = await apiAxios.get(`/api/horarios/horarios/${horarioId}`);
                    console.log(response)
                    setHorario(response.data);
                } catch (error) {
                    console.error('Erro ao carregar horário:', error);
                }
            };

            fetchHorario();
        }
    }, [horarioId, tenantId]);
    console.log(horario)

    const handleUpdateHorario = async () => {
        const { serie, turno, week } = horario;

        for (let i = 0; i < week.length; i++) {
            if (!week[i].week_day || !week[i].TimeStart || !week[i].TimeEnd || !week[i].materias[0].title) {
                enqueueSnackbar('Preencha todos os campos obrigatórios.', { variant: 'warning' });
                return;
            }
        }

        try {
            console.log('Atualizando horário:', horario);
            const response = await apiAxios.put(`/api/horarios/horarios/${horarioId}`, {
                tenantId,
                ...horario
            });
            onHorarioUpdated(response.data);
            enqueueSnackbar('Horário atualizado com sucesso!', { variant: 'success' });
        } catch (error) {
            console.error('Erro ao atualizar horário:', error.response?.data || error.message);
            enqueueSnackbar('Erro ao atualizar horário.', { variant: 'error' });
        }
    };


    const handleChange = (e, weekIndex = null, materiaIndex = null) => {
        const { name, value, type, checked } = e.target;
        const updatedHorario = { ...horario };

        const inputValue = type === 'checkbox' ? checked : value;

        if (weekIndex !== null && materiaIndex === null) {
            updatedHorario.week[weekIndex][name] = inputValue;
        } else if (weekIndex !== null && materiaIndex !== null) {
            updatedHorario.week[weekIndex].materias[materiaIndex][name] = inputValue;
        } else {
            updatedHorario[name] = inputValue;
        }

        setHorario(updatedHorario);
    };

    const handleAddMateria = (weekIndex) => {
        const updatedHorario = { ...horario };
        if (updatedHorario.week[weekIndex].materias.length < 30) {
            updatedHorario.week[weekIndex].materias.push({ TimeStart: '', TimeEnd: '', title: '', conteudo: '' });
            setHorario(updatedHorario);
        } else {
            alert('Você não pode adicionar mais de 30 matérias.');
        }
    };

    const handleRemoveMateria = (weekIndex, materiaIndex) => {
        const updatedHorario = { ...horario };
        if (updatedHorario.week[weekIndex].materias.length > 1) {
            updatedHorario.week[weekIndex].materias.splice(materiaIndex, 1);
            setHorario(updatedHorario);
        } else {
            alert('Você deve manter pelo menos 1 matéria.');
        }
    };

    const handleAddDay = () => {
        setHorario({
            ...horario,
            week: [
                ...horario.week,
                {
                    week_day: 'Segunda-feira',
                    TimeStart: '',
                    TimeEnd: '',
                    public: false,
                    materias: [{ TimeStart: '', TimeEnd: '', title: '', conteudo: '' }]
                }
            ]
        });
    };

    const handleRemoveDay = (index) => {
        if (horario.week.length > 1) {
            setHorario({
                ...horario,
                week: horario.week.filter((_, i) => i !== index)
            });
        } else {
            alert('Você deve manter pelo menos 1 dia.');
        }
    };

    return (
        <div className={styles.createHorario}>
            <div className={styles.createHorario_top}>
                <select
                    name="serie"
                    value={horario.serie}
                    onChange={handleChange}
                >
                    <option value="">Selecione a série</option>
                    {series.map((serie) => (
                        <option key={serie} value={serie}>
                            {serie}
                        </option>
                    ))}
                </select>
                <select
                    name="turno"
                    value={horario.turno}
                    onChange={handleChange}
                >
                    <option value="">Selecione o turno</option>
                    {turno.map((turno) => (
                        <option key={turno} value={turno}>
                            {turno}
                        </option>
                    ))}
                </select>
            </div>
            {horario.week && horario.week.map((day, weekIndex) => (
                <div className={styles.createHorario_bottom} key={weekIndex}>
                    <h3>{day.week_day || `Dia ${weekIndex + 1}`}</h3>
                    <div className={styles.deleteDayWeek}>
                        <button type="button" onClick={() => handleRemoveDay(weekIndex)}><MdDeleteOutline /></button>
                    </div>
                    <select
                        name="week_day"
                        value={day.week_day}
                        onChange={(e) => handleChange(e, weekIndex)}
                    >
                        <option value="Segunda-feira">Segunda-feira</option>
                        <option value="Terça-feira">Terça-feira</option>
                        <option value="Quarta-feira">Quarta-feira</option>
                        <option value="Quinta-feira">Quinta-feira</option>
                        <option value="Sexta-feira">Sexta-feira</option>
                        <option value="Sábado">Sábado</option>
                        <option value="Domingo">Domingo</option>
                    </select>
                    <input
                        type="time"
                        name="TimeStart"
                        value={day.TimeStart}
                        onChange={(e) => handleChange(e, weekIndex)}
                    />
                    <input
                        type="time"
                        name="TimeEnd"
                        value={day.TimeEnd}
                        onChange={(e) => handleChange(e, weekIndex)}
                    />
                    <div>
                        <label>Publicar horário?</label>
                        <input
                            type="checkbox"
                            name="public"
                            checked={day.public}
                            onChange={(e) => handleChange(e, weekIndex)}
                        />
                    </div>
                    <div className={styles.materiaContainer}>
                        {day.materias.map((materia, materiaIndex) => (
                            <div key={materiaIndex} className={styles.materiaContainer_item}>
                                <div className={styles.materiaContainer_item_delete}>
                                    <button type="button" onClick={() => handleRemoveMateria(weekIndex, materiaIndex)}><IoClose /></button>
                                </div>
                                <div>
                                    <label>Título:</label>
                                    <input
                                        name="title"
                                        value={materia.title}
                                        onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                    />
                                </div>
                                <div>
                                    <label>Hora Início da Matéria:</label>
                                    <input
                                        type="time"
                                        name="TimeStart"
                                        value={materia.TimeStart}
                                        onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                    />
                                </div>
                                <div>
                                    <label>Hora Fim da Matéria:</label>
                                    <input
                                        type="time"
                                        name="TimeEnd"
                                        value={materia.TimeEnd}
                                        onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                    />
                                </div>
                                <div>
                                    <label>Conteúdo:</label>
                                    <input
                                        type="text"
                                        name="conteudo"
                                        value={materia.conteudo}
                                        onChange={(e) => handleChange(e, weekIndex, materiaIndex)}
                                    />
                                </div>
                            </div>
                        ))}
                        <button className={styles.addMateria} type="button" onClick={() => handleAddMateria(weekIndex)}>Adicionar</button>
                    </div>
                </div>
            ))}
            <div className={styles.action_buttons}>
                <button className={styles.send} type="button" onClick={handleUpdateHorario}>Atualizar Horário</button>
                <button type="button" onClick={handleAddDay}>Adicionar dia da semana</button>
            </div>
        </div>
    );
};

export default EditHorarioComponent;
