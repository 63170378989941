import React from 'react'
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';
import DashboardComponent from '../../components/pagesComponents/UsersComponents/DashboardComponent';

const HomeSecretaria = () => {
    const itemsPagesList = itemsPagesWithExclusions('Painel', []);

    return (
      <DashboardComponent itemsPages={itemsPagesList}/>
    );
}

export default HomeSecretaria