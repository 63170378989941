import React from 'react';
import styles from './FichaReceivedPage.module.css';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import InterfaceStructure from '../../../../components/InterfaceStructure';
import { Link, useParams } from 'react-router-dom';
import useTenantId from '../../../../components/tenantId';
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';
import FichaReceivedComponent from '../../../../components/fichasComponent/responseComponent/FichaReceivedComponent';

const FichaReceivedPage = () => {
    const tenantId = useTenantId(); // Obtém tenantId usando o hook
    const { formId } = useParams(); // Obtém o ID do formulário da URL

    return (
        <FichaReceivedComponent formId={formId} tenantIdProp={tenantId} />
    );
};

export default FichaReceivedPage;
