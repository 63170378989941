// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './store.js';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './store';
import { ApiAxiosInterceptor } from './api/apiAxios';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

// Importações do Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Configurando o Stripe com a chave publicável
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); // Substitua com sua chave publicável

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApiAxiosInterceptor />
      <SnackbarProvider maxSnack={3} >
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
