import React, { useEffect, useMemo, useState } from 'react';
import styles from "./DashboardComponent.module.css";
import InterfaceStructure from '../../InterfaceStructure';
import apiAxios from '../../../api/apiAxios';
import Chart from '../../DashboardComponents/chart/Chart';
import WidgetSm from '../../DashboardComponents/widgetSm/WidgetSm';
// Icons
import { FaUsers } from "react-icons/fa";
import { IoGitPullRequestSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

import BasicPageStructure from '../../BasicPageStructure';

const DashboardComponent = ({ itemsPages }) => {
    const navigate = useNavigate();
    const [userStats, setUserStats] = useState([]);

    const MONTHS = useMemo(
        () => [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Agu", "Sep", "Oct", "Nov", "Dec"
        ],
        []
    );

    useEffect(() => {
        const getStats = async () => {
            try {
                const res = await apiAxios.get("api/user/stats");
                res.data.map((item) =>
                    setUserStats((prev) => [
                        ...prev,
                        { name: MONTHS[item._id - 1], "Active User": item.total },
                    ])
                );
            } catch (error) {
                console.error("Error fetching user stats:", error);
            }
        };
        getStats();
    }, [MONTHS]);

    // Array de botões a ser passado para o BasicPageStructure
    const buttons = [
        {
            label: 'Visualizar Solicitações',
            icon: IoGitPullRequestSharp,
            onClick: () => navigate('/requests'),
            type: 'primary', // Botão com estilo "primary" (vermelho)
        },
        {
            label: 'Visualizar Usuários',
            icon: FaUsers,
            onClick: () => navigate('/usuarios'),
            type: 'default', // Botão com estilo "default"
        },
        // Adicione mais botões ao array conforme necessário
    ];

    // Conteúdo do dashboard a ser passado para o BasicPageStructure
    const dashboardContent = (
        <>
            <Chart
                data={userStats}
                title="Análise de Usuários"
                grid
                dataKey="Active User"
            />
            <WidgetSm />
        </>
    );

    return (
        <InterfaceStructure
            itemsPages={itemsPages}
            dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                />
            }
        />
    );
};

export default DashboardComponent;
