import React, { useState } from 'react';
import InterfaceStructure from '../../../components/InterfaceStructure';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import styles from './CalendarioCoordenacaoCategoryCreatePage.module.css';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const CalendarioCoordenacaoCategoryCreatePage = () => {
    const [nome, setNome] = useState('');
    const [cor, setCor] = useState('#000000'); // Cor padrão preta
    const tenantId = useTenantId();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const novaCategoria = {
            tenantId: tenantId,
            category: {
                name: nome,
                color: cor,
            },
        };

        try {
            const response = await apiAxios.post('/api/calendar/category', novaCategoria);
            console.log(response);
            navigate('/calendario');
        } catch (error) {
            console.error('Erro ao criar categoria:', error);
        }
    };

    const itemsPagesList = itemsPagesWithExclusions('Calendário', ['Agenda','Informes','Atividades','Chat','Fichas','Cardápio']);


    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.returnPainelArrow}>
                    <Link to='/calendario'><MdKeyboardArrowLeft />Calendário</Link>
                </div>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Criar Categoria</h1>
                        <p>Administrador</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.BasicInfoForm}>
                        <p>Informações Básicas da Categoria</p>
                        <input
                            type="text"
                            placeholder="Nome da Categoria"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            required
                        />
                        <input
                            type="color"
                            value={cor}
                            onChange={(e) => setCor(e.target.value)}
                            className={styles.color}
                            required
                        />
                        <div className={styles.botoesDeControle}>
                            <button type="submit">Criar Categoria</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );

    return (
        <>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
        </>
    );
};

export default CalendarioCoordenacaoCategoryCreatePage;
