import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import CalendarioComponent from '../../../components/pagesComponents/CalendarioComponents/CalendarioComponent';

const CalendarioPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Calendário', []);

  return (
    <div>
      <CalendarioComponent itemsPages={itemsPagesList} accessLevel="high"/>
    </div>
  );
};

export default CalendarioPage;
