import React, { useState, useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import './CustomReactCalendar.css'; // Seu CSS customizado
import 'react-calendar/dist/Calendar.css';
import styles from './FrequenciaComponent.module.css'; // Importa o CSS Module
import apiAxios from '../../../api/apiAxios';
import InterfaceStructure from '../../InterfaceStructure';
import { series } from '../../../DataSeries';


const FrequenciaComponent = ({ itemsPages, accessName, accessLevel, professorAccess }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attendanceByDate, setAttendanceByDate] = useState([]);
    const [selectedSerie, setSelectedSerie] = useState(''); // Série selecionada
    const [selectedTurno, setSelectedTurno] = useState(''); // Turno selecionado
    const [updatingStudentId, setUpdatingStudentId] = useState(null); // ID do aluno sendo atualizado

    // Função para carregar os alunos da data selecionada
    const loadStudents = async (date) => {
        setLoading(true);
        try {
            const dateStr = date.toLocaleDateString('en-CA');
            const response = await apiAxios.get(`/api/attendance/list/${dateStr}`);
            setStudents(response.data);
        } catch (error) {
            console.error('Erro ao carregar a lista de alunos:', error);
        } finally {
            setLoading(false);
        }
    };

    // Função para carregar a frequência por data
    const loadAttendanceByDate = async () => {
        try {
            const response = await apiAxios.get('/api/attendance/byDate');
            setAttendanceByDate(response.data);
        } catch (error) {
            console.error('Erro ao carregar frequência por data:', error);
        }
    };

    useEffect(() => {
        loadStudents(selectedDate);
        loadAttendanceByDate();
    }, [selectedDate]);

    // Função para definir a classe CSS de um dia no calendário
    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateStr = date.toLocaleDateString('en-CA');
            const attendance = attendanceByDate.find((item) => item.date === dateStr);

            if (attendance) {
                const { statuses } = attendance;

                if (statuses.includes('Absent')) {
                    return styles.absentDay;
                } else if (statuses.includes('Late')) {
                    return styles.lateDay;
                } else if (statuses.includes('Excused')) {
                    return styles.excusedDay;
                } else if (statuses.length === 1 && statuses[0] === 'Present') {
                    return styles.presentDay;
                }
            }
        }
        return null;
    };

    // Função para marcar a frequência de um aluno
    const markAttendance = async (studentId, status) => {
        setUpdatingStudentId(studentId);
        try {
            const dateStr = selectedDate.toLocaleDateString('en-CA');
            await apiAxios.post('/api/attendance/mark', {
                userId: studentId,
                date: dateStr,
                status,
            });
            // Atualizar o estado localmente
            setStudents(prevStudents =>
                prevStudents.map(student =>
                    student._id === studentId ? { ...student, status } : student
                )
            );
        } catch (error) {
            console.error('Erro ao marcar frequência:', error);
        } finally {
            setUpdatingStudentId(null);
        }
    };

    // Filtra os alunos com base na série e no turno selecionados
    const filteredStudents = students.filter((student) => {
        const matchesSerie = selectedSerie ? student?.serie === selectedSerie : true;
        const matchesTurno = selectedTurno ? student?.turno === selectedTurno : true;
        return matchesSerie && matchesTurno;
    });

    const handleSerieChange = (e) => {
        setSelectedSerie(e.target.value);
    };

    const handleTurnoChange = (e) => {
        setSelectedTurno(e.target.value);
    };

    // Filtra as séries e turnos de acordo com o acesso do professor
    const allowedSeries = professorAccess?.series || series;
    const allowedTurnos = professorAccess?.turnos || ['Manhã', 'Tarde', 'Noite'];

    const dashboardContent = (
        <div className={styles.allPage}>
            <div className={styles.home}>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Frequência</h1>
                        <p>{accessName}</p>
                    </div>
                    <div className={styles.end}></div>
                </div>
                <div className={styles.bottomAreaInfo}>
                    <div className={styles.all}>
                        <div className={styles.left} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={styles.calendar}>
                                <ReactCalendar
                                    onChange={setSelectedDate}
                                    value={selectedDate}
                                    locale="pt-BR"
                                    tileClassName={tileClassName}
                                    prev2Label={null}
                                    next2Label={null}
                                />
                            </div>
                            <div className={styles.legend}>
                                <h4>Legenda:</h4>
                                <ul>
                                    <li><span className={`${styles.legendColor} ${styles.legendGreen}`}></span> Todos Presentes</li>
                                    <li><span className={`${styles.legendColor} ${styles.legendRed}`}></span> Algum Ausente</li>
                                    <li><span className={`${styles.legendColor} ${styles.legendOrange}`}></span> Algum Atrasado</li>
                                    <li><span className={`${styles.legendColor} ${styles.legendBlue}`}></span> Algum Justificado</li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <h2>Frequência para o dia {selectedDate.toLocaleDateString('pt-BR')}</h2>

                            <div className={styles.filters}>
                                <div>
                                    <label htmlFor="serieSelect">Série:</label>
                                    <select id="serieSelect" value={selectedSerie} onChange={handleSerieChange}>
                                        <option value="">Todas as Séries</option>
                                        {allowedSeries.map((serie) => (
                                            <option key={serie} value={serie}>{serie}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="turnoSelect">Turno:</label>
                                    <select id="turnoSelect" value={selectedTurno} onChange={handleTurnoChange}>
                                        <option value="">Todos os Turnos</option>
                                        {allowedTurnos.map((turno) => (
                                            <option key={turno} value={turno}>{turno}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div>
                                {loading ? (
                                    <p>Carregando alunos...</p>
                                ) : filteredStudents.length === 0 ? (
                                    <p>Nenhum aluno encontrado para esta data.</p>
                                ) : (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Matrícula</th>
                                                <th>Série</th>
                                                <th>Turno</th>
                                                <th>Presente</th>
                                                <th>Ausente</th>
                                                <th>Atrasado</th>
                                                <th>Justificado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredStudents.map((student) => (
                                                <tr key={student._id}>
                                                    <td>{student.firstName} {student.lastName}</td>
                                                    <td>{student.matricula}</td>
                                                    <td>{student?.serie || 'N/A'}</td>
                                                    <td>{student?.turno || 'N/A'}</td>
                                                    <td>
                                                        <button
                                                            onClick={() => markAttendance(student._id, 'Present')}
                                                            className={`${styles.statusButton} ${student.status === 'Present' ? `${styles.active} ${styles.present}` : ''}`}
                                                            disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                            aria-pressed={student.status === 'Present'}
                                                            aria-label={`Marcar como Presente para ${student.firstName} ${student.lastName}`}
                                                        ></button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => markAttendance(student._id, 'Absent')}
                                                            className={`${styles.statusButton} ${student.status === 'Absent' ? `${styles.active} ${styles.absent}` : ''}`}
                                                            disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                            aria-pressed={student.status === 'Absent'}
                                                            aria-label={`Marcar como Ausente para ${student.firstName} ${student.lastName}`}
                                                        ></button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => markAttendance(student._id, 'Late')}
                                                            className={`${styles.statusButton} ${student.status === 'Late' ? `${styles.active} ${styles.late}` : ''}`}
                                                            disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                            aria-pressed={student.status === 'Late'}
                                                            aria-label={`Marcar como Atrasado para ${student.firstName} ${student.lastName}`}
                                                        ></button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => markAttendance(student._id, 'Excused')}
                                                            className={`${styles.statusButton} ${student.status === 'Excused' ? `${styles.active} ${styles.excused}` : ''}`}
                                                            disabled={updatingStudentId === student._id || accessLevel === 'low'}
                                                            aria-pressed={student.status === 'Excused'}
                                                            aria-label={`Marcar como Justificado para ${student.firstName} ${student.lastName}`}
                                                        ></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />;
};

export default FrequenciaComponent;
