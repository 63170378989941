import React, { useState, useEffect } from 'react';
import apiAxios from '../../../api/apiAxios'; // Ajuste o caminho conforme necessário
import { useSnackbar } from 'notistack'; // Importando notistack
import styles from './FichaEditReceivedComponent.module.css';

const FichaEditReceivedPage = ({ response }) => {
    const [responseData, setResponseData] = useState(response);
    const { enqueueSnackbar } = useSnackbar(); // Usando notistack

    useEffect(() => {
        setResponseData(response);
    }, [response]);

    const handleAnswerChange = (index, e) => {
        const { value } = e.target;
        const updatedAnswers = [...responseData.answers];
        updatedAnswers[index] = { ...updatedAnswers[index], answer: value };
        setResponseData({ ...responseData, answers: updatedAnswers });
    };

    const handleDateChange = (e) => {
        const { value } = e.target;
        const date = new Date(value);
        const isoDate = date.toISOString();
        setResponseData({ ...responseData, createdAt: isoDate });
    };

    const handleSave = async () => {
        try {
            await apiAxios.put(`/api/formresponse/${responseData._id}`, responseData);
            enqueueSnackbar('Resposta atualizada com sucesso!', { variant: 'success' }); // Exibindo notificação de sucesso
        } catch (error) {
            console.error('Erro ao atualizar resposta:', error);
            enqueueSnackbar('Erro ao atualizar resposta.', { variant: 'error' }); // Exibindo notificação de erro
        }
    };

    return (
        <div className={styles.popUp}>
            <div className={styles.content}>
                <h1>Editar Resposta do Formulário</h1>
                <div className={styles.form}>
                    {responseData && responseData.form.fields.map((question, index) => {
                        const answer = responseData.answers.find(a => a.fieldId === question._id);
                        return (
                            <div className={styles.group} key={index}>
                                <label>{question.name}</label>
                                <input
                                    type="text"
                                    value={answer ? answer.answer : ''}
                                    onChange={(e) => handleAnswerChange(index, e)}
                                />
                            </div>
                        );
                    })}
                    <div className={styles.group}>
                        <label htmlFor="createdAt">Data de Criação:</label>
                        <input
                            id="createdAt"
                            type="datetime-local"
                            value={responseData.createdAt ? new Date(responseData.createdAt).toISOString().slice(0, 16) : ''}
                            onChange={handleDateChange}
                        />
                    </div>
                </div>
                <div className={styles.actions}>
                    <button style={{ background: "#c72a2f", color: "white" }} onClick={handleSave}>Salvar</button>
                </div>
            </div>
        </div>
    );
};

export default FichaEditReceivedPage;
