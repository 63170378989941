// frontend/src/pages/AssinaturaPage.js
import React, { useState, useEffect } from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import InterfaceStructure from '../../../components/InterfaceStructure';
import PaymentsFormComponent from '../../../components/stripe/PaymentsFormComponent';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';

const AssinaturaPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Painel', []);
    const tId = useTenantId();
    const [tenantId, setTenantId] = useState(tId); // ID do Tenant, substitua com o valor correto
    const [priceId, setPriceId] = useState(null);

    // Buscar o priceId ao carregar a página
    useEffect(() => {
        const fetchPriceId = async () => {
            try {
                const response = await apiAxios.get('/api/stripe/get-price-id'); // Certifique-se de que esta rota está configurada corretamente no backend
                setPriceId(response.data.priceId);
            } catch (error) {
                console.error('Erro ao obter o priceId:', error);
            }
        };

        fetchPriceId();
    }, []);
    console.log(priceId)

    const dashboardContent = (
        <>
            <h2>Página de Assinatura</h2>
            <p>Adicione o seu método de pagamento para iniciar a assinatura mensal.</p>
            {/* Renderiza o PaymentsFormComponent se o priceId estiver disponível */}
            {priceId ? (
                <PaymentsFormComponent tenantId={tenantId} priceId={priceId} />
            ) : (
                <p>Carregando detalhes da assinatura...</p>
            )}
        </>
    );

    return (
        <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
    );
};

export default AssinaturaPage;
