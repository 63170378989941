import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import InformeComponents from '../../../components/pagesComponents/InformeComponents/InformeComponents';

const InformesSecretariaPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Informes', []);

    return (
        <InformeComponents itemsPages={itemsPagesList} />
    );
};

export default InformesSecretariaPage;
