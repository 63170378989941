import React, { useState, useEffect } from 'react';
import apiAxios from '../../../../api/apiAxios';
import styles from './ItemCardapioEditComponent.module.css';
import { IoIosArrowUp } from 'react-icons/io';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ItemCardapioEditComponent = ({ tenantId, item, onClose, setCategories }) => {
    const [titulo, setTitulo] = useState(item.titulo);
    const [descricao, setDescricao] = useState(item.descricao || '');
    const [isClosing, setIsClosing] = useState(false); // Controla a animação de fechamento

    // Função para mostrar o aviso de salvamento
    const showSavingToast = () => {
        toast.info('Salvando...', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        });
    };

    // Função para atualizar o item automaticamente
    const updateItemAutomatically = async (updatedFields) => {
        showSavingToast(); // Mostra o aviso de salvamento
        try {
            const updatedItem = { ...item, ...updatedFields };

            await apiAxios.patch(`/api/card/item/items/${tenantId}/${item._id}`, updatedItem);

            // Atualiza o estado das categorias com o item editado
            setCategories((prevCategories) =>
                prevCategories.map((category) =>
                    category._id === item.categoryId
                        ? {
                            ...category,
                            items: category.items.map((it) =>
                                it._id === item._id ? { ...it, ...updatedItem } : it
                            ),
                        }
                        : category
                )
            );
        } catch (error) {
            console.error('Erro ao atualizar item automaticamente:', error);
        }
    };

    // Efeito para monitorar alterações no título
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            updateItemAutomatically({ titulo });
        }, 500); // Aguarda 500ms antes de salvar a alteração

        return () => clearTimeout(delayDebounceFn);
    }, [titulo]);

    // Efeito para monitorar alterações na descrição
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            updateItemAutomatically({ descricao });
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [descricao]);

    return (
        <div className={`${styles.popup} ${isClosing ? styles.closing : ''}`}>
            <div className={styles.return}>
                <button
                    className={`${styles.closeIcon} ${isClosing ? styles.flipDown : ''}`}
                    onClick={() => {
                        setIsClosing(true);
                        setTimeout(onClose, 300); // Aguarda a animação antes de fechar
                    }}
                    aria-label="Fechar Popup"
                >
                    <IoIosArrowUp />
                </button>
            </div>
            <h2>Editar Item</h2>
            <div className={styles.formGroup}>
                <label>Título</label>
                <input
                    type="text"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    required
                />
            </div>
            <div className={styles.formGroup}>
                <label>Descrição</label>
                <textarea
                    className={styles.textarea}
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                />
            </div>
            <ToastContainer />
        </div>
    );
};

export default ItemCardapioEditComponent;
