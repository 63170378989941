import React from 'react';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import MateriasComponent from '../../../components/pagesComponents/materiasComponents/MateriasComponent';

const MateriasAgenda = () => {
    const itemsPagesList = itemsPagesWithExclusions('Agenda', []);
    return (
      <>
        <MateriasComponent itemsPages={itemsPagesList} returnLink={"/agenda"} returnLinkName={"Agenda"}/>
      </>
    )
}

export default MateriasAgenda;
