import React, { useState } from 'react';

import CardapioComponentCreate from '../../../components/pagesComponents/CardapioComponents/CardapioComponentCreate';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const CardapioNutricionistaPageCreate = () => {
  const pagesList = itemsPagesWithExclusions('Horários', ['Agenda', 'Informes', 'Atividades', 'Fichas', 'Planos']);
  return (
    <>
      <CardapioComponentCreate pagesList={pagesList}/>
    </>
  );
};

export default CardapioNutricionistaPageCreate;
