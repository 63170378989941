import React, { useState, useEffect } from 'react';
import apiAxios from '../../../api/apiAxios';
import moment from 'moment';
import styles from './CardapioComponentEditPage.module.css';
import useTenantId from '../../tenantId';
import Select from 'react-select';
import { useSnackbar } from 'notistack'; 
import { FaRegTrashAlt, FaRegSave } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import tinycolor from 'tinycolor2';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const getTextColor = (bgColor) => {
  if (!bgColor) return '#000000';
  const color = tinycolor(bgColor);
  return color.isLight() ? '#000000' : '#FFFFFF';
};

const customStyles = {
  option: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.categoriaColor,
    color: getTextColor(data.categoriaColor),
    padding: '5px',
    borderRadius: '5px'
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.categoriaColor,
    color: getTextColor(data.categoriaColor),
  }),
  multiValueLabel: (provided, { data }) => ({
    ...provided,
    color: getTextColor(data.categoriaColor),
  }),
};

const CardapioComponentEditPage = ({ cardapio, onSave }) => {
  const { enqueueSnackbar } = useSnackbar();
  const diasDaSemana = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'];
  const tenantId = useTenantId();

  const [Titulo, setTitulo] = useState(cardapio?.Titulo || '');
  const [dateFormatted, setDateFormatted] = useState('');
  const [dateFormattedEnd, setDateFormattedEnd] = useState('');
  const [alimentacao, setAlimentacao] = useState(cardapio?.alimentacao || []);
  const [itensDisponiveis, setItensDisponiveis] = useState([]);
  const [observacao, setObservacao] = useState(
    cardapio?.observacao
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(cardapio.observacao)))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    if (cardapio) {
      setDateFormatted(moment(cardapio.dateFormatted).format('YYYY-MM-DD'));
      setDateFormattedEnd(moment(cardapio.dateFormattedEnd).format('YYYY-MM-DD'));
    }
  }, [cardapio]);

  useEffect(() => {
    const fetchItensCardapio = async () => {
      try {
        const response = await apiAxios.get(`/api/card/item/items/${tenantId}`);
        const itens = response.data.map(item => ({
          value: item._id,
          label: item.titulo,
          categoriaColor: item.group?.Color
        }));
        setItensDisponiveis(itens);
      } catch (error) {
        console.error('Erro ao buscar itens do cardápio:', error);
      }
    };

    fetchItensCardapio();
  }, [tenantId]);

  const handleRemoveRefeicao = (index) => {
    const newAlimentacao = alimentacao.filter((_, i) => i !== index);
    setAlimentacao(newAlimentacao);
  };

  const handleRefeicaoChange = (index, event) => {
    const newAlimentacao = [...alimentacao];
    newAlimentacao[index].refeicao = event.target.value;
    setAlimentacao(newAlimentacao);
  };

  const handleItemCardapioChange = (index, conteudoIndex, selectedItems) => {
    const newAlimentacao = [...alimentacao];
    newAlimentacao[index].conteudo[conteudoIndex].itensCardapio = selectedItems ? selectedItems.map(item => item.value) : [];
    setAlimentacao(newAlimentacao);
  };

  const handleAddRefeicao = () => {
    setAlimentacao([
      ...alimentacao,
      {
        refeicao: '',
        conteudo: diasDaSemana.map(dia => ({ diaDaSemana: dia, itensCardapio: [] }))
      }
    ]);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const updatedCardapio = {
        Titulo,
        dateFormatted,
        dateFormattedEnd,
        alimentacao,
        observacao: JSON.stringify(convertToRaw(observacao.getCurrentContent())) // Salva o conteúdo do editor como JSON
      };

      const response = await apiAxios.patch(`/api/card/${tenantId}/${cardapio._id}`, updatedCardapio);
      const updatedData = response.data;

      setTitulo(updatedData.Titulo);
      setDateFormatted(moment(updatedData.dateFormatted).format('YYYY-MM-DD'));
      setDateFormattedEnd(moment(updatedData.dateFormattedEnd).format('YYYY-MM-DD'));
      setAlimentacao(updatedData.alimentacao);

      onSave(updatedData);
      enqueueSnackbar('Cardápio atualizado com sucesso!', { variant: 'success' });
    } catch (error) {
      console.error('Erro ao atualizar cardápio:', error.message);
      enqueueSnackbar('Erro ao atualizar o cardápio.', { variant: 'error' });
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSave}>
      <div className={styles.formGroup}>
        <h4>Título:</h4>
        <input
          type="text"
          value={Titulo}
          onChange={(e) => setTitulo(e.target.value)}
          placeholder='Título'
          required
        />
        <h4>Data do Calendário:</h4>
        <input
          type="date"
          value={dateFormatted}
          onChange={(e) => setDateFormatted(e.target.value)}
          required
        />
        <h4>Data Final do Calendário:</h4>
        <input
          type="date"
          value={dateFormattedEnd}
          onChange={(e) => setDateFormattedEnd(e.target.value)}
          required
        />
      </div>
      <div className={styles.alimentacaoContainer}>
        {alimentacao.map((item, index) => (
          <div key={index} className={styles.refeicaoContainer}>
            <button type="button" onClick={() => handleRemoveRefeicao(index)} className={styles.removeButton}>
              <FaRegTrashAlt />
            </button>
            <h3>{item.refeicao === "" ? `Refeição ${index + 1}` : `Refeição: ${item.refeicao}`}</h3>
            <input
              type="text"
              value={item.refeicao}
              onChange={(e) => handleRefeicaoChange(index, e)}
              placeholder="Café | Almoço | Janta | etc..."
              className={styles.inputRefeicao}
              required
            />
            {item.conteudo.map((conteudo, conteudoIndex) => (
              <div key={conteudoIndex} className={styles.diaContainer}>
                <h4>{conteudo.diaDaSemana}</h4>
                <Select
                  isMulti
                  options={itensDisponiveis}
                  styles={customStyles}
                  value={itensDisponiveis.filter(option => conteudo.itensCardapio.includes(option.value))}
                  onChange={(selectedItems) => handleItemCardapioChange(index, conteudoIndex, selectedItems)}
                  placeholder="Selecione os itens do cardápio"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.formGroup}>
        <h4>Observação (opcional):</h4>
        <Editor
          editorState={observacao}
          onEditorStateChange={setObservacao}
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editor}
          toolbarClassName={styles.toolbar}
          placeholder="Escreva a observação do cardápio..."
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'history'],
            inline: { options: ['bold', 'italic', 'underline'] },
            list: { options: ['unordered', 'ordered'] },
          }}
          editorStyle={{ padding: '10px', minHeight: '150px', border: '1px solid #e0e0e0', borderRadius: '4px' }}
        />
      </div>
      <div className={styles.botoesDeControle}>
        <button className={styles.addButton} type="submit"><FaRegSave /> Salvar Cardápio</button>
        <button type="button" onClick={handleAddRefeicao}>
          <IoMdAddCircleOutline /> Adicionar Refeição
        </button>
      </div>
    </form>
  );
};

export default CardapioComponentEditPage;
