import React, { useState, useEffect, useRef } from 'react';
import styles from './AgendaCreateComponent.module.css';
import { useNavigate } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { RiDeleteBinLine, RiDeleteBin5Line } from "react-icons/ri";
import Message from '../tools/Message';
import { series, turno } from "../../../DataSeries";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../../../pages/firebase';

const AgendaCreateComponent = ({ serie, events }) => {
    const [materia, setMateria] = useState([]);
    const [selectedSerie, setSelectedSerie] = useState(serie || '');
    const [selectedTurno, setSelectedTurno] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [selectedImage, setSelectedImage] = useState(null);
    const [imgUrl, setImgUrl] = useState('');
    const tenantId = useTenantId();
    const navigate = useNavigate();
    const [materiasCarregadas, setMateriasCarregadas] = useState(true);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [initialMateria, setInitialMateria] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const storage = getStorage(app);

    useEffect(() => {
        if (feedbackMessage) {
            const timer = setTimeout(() => setFeedbackMessage(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [feedbackMessage]);

    useEffect(() => {
        const formattedDate = moment(startDate).format("YYYY-MM-DD");
        const hasConflict = events.some(event => {
            const eventDate = moment(event.date).format("YYYY-MM-DD");
            return (
                eventDate === formattedDate &&
                event.serie === selectedSerie &&
                event.turno === selectedTurno
            );
        });

        if (hasConflict) {
            setFeedbackMessage({
                type: 'warning',
                message: 'Já existe uma agenda para essa série, turno e data. Altere a série, turno ou data para continuar.'
            });
            setIsSubmitDisabled(true);
        } else {
            setFeedbackMessage(null);
            setIsSubmitDisabled(false);
        }
    }, [selectedSerie, selectedTurno, startDate, events]);

    useEffect(() => {
        const fetchMaterias = async () => {
            if (selectedSerie) {
                try {
                    const response = await apiAxios.get(`/api/mat/materias?serie=${selectedSerie}&tenantId=${tenantId}`);
                    const materiasDaSerie = response.data.filter(mat => mat.serie === selectedSerie);

                    if (materiasDaSerie.length === 0) {
                        setMateriasCarregadas(false);
                        setMateria([]);
                    } else {
                        const materiasFormatadas = materiasDaSerie.map(mat => ({
                            ...mat,
                            materia: mat.materia.map(item => ({
                                ...item,
                                objetivoConhecimento: '',
                                habilidades: '',
                                metodologia: '',
                                material: '',
                            }))
                        }));

                        setMateria(materiasFormatadas);
                        setInitialMateria(JSON.parse(JSON.stringify(materiasFormatadas)));
                        setMateriasCarregadas(true);
                    }
                } catch (error) {
                    console.error('Erro ao buscar matérias:', error);
                    setFeedbackMessage({ type: 'error', message: 'Erro ao buscar matérias.' });
                }
            }
        };

        fetchMaterias();
    }, [selectedSerie, tenantId]);

    const handleSerieChange = (e) => {
        setSelectedSerie(e.target.value);
        setMateria([]);
    };

    const handleTurnoChange = (e) => {
        setSelectedTurno(e.target.value);
    };

    const handleMouseEnter = (index, matIndex) => setHoveredIndex({ index, matIndex });
    const handleMouseLeave = () => setHoveredIndex(null);

    const handleMateriaChange = (index, matIndex, field, value) => {
        const updatedMateria = [...materia];
        updatedMateria[index].materia[matIndex] = {
            ...updatedMateria[index].materia[matIndex],
            [field]: value
        };
        setMateria(updatedMateria);
    };

    const handleRemoveMateria = (index, matIndex) => {
        const updatedMateria = [...materia];
        updatedMateria[index].materia.splice(matIndex, 1);
        if (updatedMateria[index].materia.length === 0) {
            updatedMateria.splice(index, 1);
        }
        setMateria(updatedMateria);
    };

    const handleUndo = () => setMateria(JSON.parse(JSON.stringify(initialMateria)));

    const handleImageUpload = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleImageDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        setSelectedImage(e.dataTransfer.files[0]);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const uploadImageToFirebase = async () => {
        if (!selectedImage) return '';

        const imageRef = ref(storage, `images/${selectedImage.name}`);
        await uploadBytes(imageRef, selectedImage);
        const url = await getDownloadURL(imageRef);
        setImgUrl(url);
        return url;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitDisabled) return;

        const formattedDate = moment(startDate).format("DD/MM/YYYY HH:mm:ss");

        const materiaSimplificada = materia.flatMap(item => (
            item.materia.map(mat => ({
                ...item,
                materia: mat.materia,
                color: mat.color,
                description: mat.description,
                objetivoConhecimento: mat.objetivoConhecimento || '',
                habilidades: mat.habilidades || '',
                metodologia: mat.metodologia || '',
                material: mat.material || '',
            }))
        ));

        const imgUrl = await uploadImageToFirebase();

        const novaAgenda = {
            tenantId,
            date: formattedDate,
            serie: selectedSerie,
            turno: selectedTurno,
            materia: materiaSimplificada,
            imgUrl: imgUrl || '',
            agendastatus: false,
        };

        try {
            await apiAxios.post('/api/agenda/', novaAgenda);
            setFeedbackMessage({ type: 'success', message: 'Agenda criada com sucesso!' });
            navigate('/agenda');
        } catch (error) {
            console.error('Erro ao criar agenda:', error);
            setFeedbackMessage({ type: 'error', message: 'Erro ao criar agenda.' });
        }
    };

    const renderMaterias = () => {
        if (!materiasCarregadas) {
            return <p>Nenhuma matéria encontrada para a série selecionada.</p>;
        }

        return materia.map((item, index) => (
            <div key={index} className={styles.materiaBlock}>
                {item.materia.map((mat, matIndex) => (
                    <div key={`${index}-${matIndex}`} className={styles.materiaItem}>
                        <div className={styles.materiaItemHeader}>
                            <h2 style={{ borderLeft: `4px solid ${mat.color}` }}>{mat.materia}</h2>
                            <button
                                onClick={() => handleRemoveMateria(index, matIndex)}
                                className={styles.removeButton}
                                onMouseEnter={() => handleMouseEnter(index, matIndex)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {hoveredIndex && hoveredIndex.index === index && hoveredIndex.matIndex === matIndex
                                    ? <RiDeleteBin5Line />
                                    : <RiDeleteBinLine />}
                            </button>
                        </div>
                        <input
                            type="text"
                            placeholder="Objetivo de Conhecimento"
                            value={mat.objetivoConhecimento}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'objetivoConhecimento', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Habilidades"
                            value={mat.habilidades}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'habilidades', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Metodologia"
                            value={mat.metodologia}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'metodologia', e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Material"
                            value={mat.material}
                            onChange={(e) => handleMateriaChange(index, matIndex, 'material', e.target.value)}
                        />
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <>
            {feedbackMessage && <Message type={feedbackMessage.type} message={feedbackMessage.message} />}
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.BasicInfoForm}>
                    <select
                        value={selectedSerie}
                        onChange={handleSerieChange}
                        className={styles.select}
                        required
                    >
                        <option value="" disabled>Selecione a Série</option>
                        {series.map((s, index) => (
                            <option key={index} value={s}>{s}</option>
                        ))}
                    </select>
                    <select
                        value={selectedTurno}
                        onChange={handleTurnoChange}
                        className={styles.select}
                        required
                    >
                        <option value="" disabled>Selecione o Turno</option>
                        {turno.map((t, index) => (
                            <option key={index} value={t}>{t}</option>
                        ))}
                    </select>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Hora"
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="Selecione a data e hora"
                        className={styles.datePicker}
                        required
                    />

                    <p>Insira uma imagem:</p>
                    <div
                        className={`${styles.imagedroparea} ${isDragging ? styles.dragActive : ''}`}
                        onDrop={handleImageDrop}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onClick={() => fileInputRef.current.click()}
                    >
                        Solte a imagem aqui ou clique para selecionar
                        <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                    </div>

                    {selectedImage && (
                        <div className={styles.previewContainer}>
                            <img src={URL.createObjectURL(selectedImage)} alt="Pré-visualização" className={styles.previewImage} />
                            <button onClick={() => setSelectedImage(null)} className={styles.removeButton}>X</button>
                        </div>
                    )}

                    {!isSubmitDisabled && (
                        <>
                            {renderMaterias()}
                            <div className={styles.controlButton}>
                                <button type="submit" className={styles.submitButton}>
                                    Criar Agenda
                                </button>
                                <button type="button" onClick={handleUndo}>
                                    Desfazer
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    );
};

export default AgendaCreateComponent;
