import React from 'react';
import AgendaComponent from '../../../components/pagesComponents/AgendaComponents/AgendaComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const AgendaSecretariaPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Agenda', []);

    return (
        <AgendaComponent PagesList={itemsPagesList}/>
    );
};

export default AgendaSecretariaPage;
