import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styles from './AgendaComponent.module.css';
import { IoIosAddCircleOutline } from 'react-icons/io';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import InterfaceStructure from '../../../components/InterfaceStructure';
import AgendaViewComponent from './AgendaView/AgendaViewComponent';
import AgendaEditComponent from './AgendaEditComponent';
import { LiaSchoolSolid } from "react-icons/lia";
import BasicPageStructure from '../../BasicPageStructure';
import AgendaCreateComponent from './AgendaCreateComponent';
import PopUpComponent from '../../popUp/PopUpComponent';
import { series, turno } from '../../../DataSeries'; 
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../tenantId';

const AgendaComponent = ({ PagesList }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [hasEvents, setHasEvents] = useState(false);
    const [isCreateAgendaOpen, setIsCreateAgendaOpen] = useState(false);
    const [isViewAgendaOpen, setIsViewAgendaOpen] = useState(false);
    const [isEditAgendaOpen, setIsEditAgendaOpen] = useState(false);
    const [selectedSeries, setSelectedSeries] = useState('1º ano');
    const [selectedTurno, setSelectedTurno] = useState('Manhã'); // Novo estado para o turno
    const [selectedEventId, setSelectedEventId] = useState(null);
    const tenantId = useTenantId();

    const navigate = useNavigate();

    const fetchEvents = async () => {
        try {
            const response = await apiAxios.get('/api/agenda/' + tenantId);
            const formattedEvents = response.data.map(event => ({
                ...event,
                date: new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')),
            }));
            setEvents(formattedEvents);
        } catch (error) {
            console.error('Erro ao buscar eventos:', error);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    const handleSeriesChange = (event) => {
        setSelectedSeries(event.target.value);
    };

    const handleTurnoChange = (event) => {
        setSelectedTurno(event.target.value);
    };

    const filteredEvents = events.filter(event =>
        (!selectedSeries || event.serie === selectedSeries) &&
        (!selectedTurno || event.turno === selectedTurno)
    );

    const handleDateClick = (date) => {
        const formattedDate = date.toISOString().split('T')[0];
        const eventExists = filteredEvents.some(event => {
            const eventDate = event.date.toISOString().split('T')[0];
            return eventDate === formattedDate;
        });

        if (eventExists) {
            setSelectedDate(date);
            setHasEvents(true);
            setIsViewAgendaOpen(true);
        } else {
            setSelectedDate(null);
            setHasEvents(false);
        }
    };

    const closeViewAgendaPopup = () => {
        setSelectedDate(null);
        setHasEvents(false);
        setIsViewAgendaOpen(false);
    };

    const openCreateAgendaPopup = () => {
        setIsCreateAgendaOpen(true);
        fetchEvents();
    };

    const closeCreateAgendaPopup = () => {
        setIsCreateAgendaOpen(false);
        fetchEvents();
    };

    const openEditPopup = (eventId) => {
        setSelectedEventId(eventId);
        setIsViewAgendaOpen(false);
        setIsEditAgendaOpen(true);
    };

    const closeEditPopup = () => {
        setIsEditAgendaOpen(false);
        setIsViewAgendaOpen(true);
        fetchEvents();
    };

    const highlightDates = ({ date, view }) => {
        if (view === 'month') {
            const dateString = date.toISOString().split('T')[0];
            const event = filteredEvents.find(event => {
                const eventDate = event.date;
                return eventDate && eventDate.toISOString().split('T')[0] === dateString;
            });

            if (event) {
                return (
                    <div
                        className={styles.highlight}
                        style={{ backgroundColor: event.agendastatus ? '#ffde59' : '#ec3237' }}
                    />
                );
            }
        }
        return null;
    };

    const dashboardContent = (
        <div className={styles.calendarWrapper}>
            <div className={styles.filterWrapper}>
                <div>
                    <label>Série:</label>
                    <select value={selectedSeries} onChange={handleSeriesChange}>
                        {series.map((serie) => (
                            <option key={serie} value={serie}>
                                {serie}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Turno:</label>
                    <select value={selectedTurno} onChange={handleTurnoChange}>
                        {turno.map((turno) => (
                            <option key={turno} value={turno}>
                                {turno}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <Calendar
                onClickDay={handleDateClick}
                prev2Label={null}
                next2Label={null}
                tileContent={highlightDates}
                className={styles.reactCalendar}
            />

            <div className={styles.categories}>
                <h2>Categorias</h2>
                <div className={styles.category}>
                    <span style={{ borderLeft: '3px solid #c72a2f' }}>Liberado para Alunos</span>
                </div>
                <div className={styles.category}>
                    <span style={{ borderLeft: '3px solid #FFDE59' }}>Não Liberado para Alunos</span>
                </div>
            </div>
        </div>
    );

    const buttons = [
        {
            label: 'Adicionar Matérias',
            icon: LiaSchoolSolid,
            onClick: () => navigate('/agenda/materias'),
        },
        {
            label: 'Adicionar agenda',
            icon: IoIosAddCircleOutline,
            onClick: openCreateAgendaPopup,
        }
    ];

    return (
        <div>
            <InterfaceStructure itemsPages={PagesList} dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={false}
                    namePage="Agenda"
                    accessType='administrador'
                />
            } />

            <PopUpComponent
                title="Criar Nova Agenda"
                isOpen={isCreateAgendaOpen}
                onClose={closeCreateAgendaPopup}
            >
                <AgendaCreateComponent serie={selectedSeries} events={events} />
            </PopUpComponent>

            <PopUpComponent
                title={`Eventos para ${selectedSeries || 'Todas as Séries'}`}
                isOpen={isViewAgendaOpen}
                onClose={closeViewAgendaPopup}
            >
                <AgendaViewComponent
                    date={selectedDate}
                    selectedSeries={selectedSeries}
                    selectedTurno={selectedTurno} 
                    onEdit={openEditPopup}
                />
            </PopUpComponent>

            <PopUpComponent
                title="Editar Agenda"
                isOpen={isEditAgendaOpen}
                onClose={closeEditPopup}
            >
                <AgendaEditComponent agendaId={selectedEventId} serie={selectedSeries}  turno={selectedTurno} />
            </PopUpComponent>
        </div>
    );
};

export default AgendaComponent;
