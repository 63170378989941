import React from 'react';
import CardapioComponentCreate from '../../../components/pagesComponents/CardapioComponents/CardapioComponentCreate';

const CardapioSecretariaPageCreate = () => {
  return (
    <>
      <CardapioComponentCreate />
    </>
  );
};

export default CardapioSecretariaPageCreate;
