import { AiOutlineEye } from "react-icons/ai";
import { useEffect, useState } from "react";
import styles from "./widgetSm.module.css";
import apiAxios from "../../../api/apiAxios";
import { useSelector } from "react-redux";

export default function WidgetSm() {
  const [users, setUsers] = useState([]);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await apiAxios.get(`api/user/users/${tokenPayload.tenantId}?new=true`);
        const lastFourUsers = res.data.users.slice(-4);
        setUsers(lastFourUsers);
      } catch (err) {
        console.error(err);
      }
    };
    getUsers();
  }, [tokenPayload.tenantId]);
  
  return (
    <div className={styles.widgetSm}>
      <span className={styles.widgetSmTitle}>Novos membros</span>
      <ul className={styles.widgetSmList}>
        {users.slice().reverse().map((user) => (
          <li className={styles.widgetSmListItem} key={user._id}>
            <img
              src={
                user.img ||
                "https://crowd-literature.eu/wp-content/uploads/2015/01/no-avatar.gif"
              }
              alt=""
              className={styles.widgetSmImg}
            />
            <div className={styles.widgetSmUser}>
              <span className={styles.widgetSmUsername}>{user.firstName}</span>
            </div>
            <button className={styles.widgetSmButton}>
              <AiOutlineEye className={styles.widgetSmIcon} />
              Visualizar
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
