import React, { useEffect, useState } from 'react';
import InterfaceStructure from '../../components/InterfaceStructure';
import { MdKeyboardArrowLeft, MdOutlineFormatAlignJustify } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import { MdDeleteOutline } from 'react-icons/md';
import styles from './UsuariosCoordenacao.module.css';
import { Link } from 'react-router-dom';
import { deleteUserById, getAllUsers } from '../../actions/UserActions';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../components/FormatNumber';
import { itemsPagesWithExclusions } from '../../components/itemsPage/itemsPage';

const UsuariosCoordenacao = () => {
  const itemsPagesList = itemsPagesWithExclusions('Painel', ['Agenda','Informes','Atividades','Chat','Fichas','Cardápio']);

  const [data, setData] = useState([]);
  const userInfo = useSelector(state => state.user.userInfo);

  useEffect(() => {
    if (!userInfo || !userInfo.token) return;

    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const loggedInUserId = tokenPayload._id;

    const fetchData = async () => {
      try {
        const users = await getAllUsers(tokenPayload.tenantId);
        const filteredUsers = users.filter(user =>
          user._id !== loggedInUserId && 
          !user.isAdminMaster &&
          !user.isAdmin && 
          !user.isCoordenaçao && 
          !user.isSecretaria && 
          !user.isDiretor
        );
        const mappedUsers = filteredUsers.slice().reverse().map(user => ({
          id: user._id,
          Number: formatPhoneNumber(user.phoneNumber),
          user: user.firstName,
          usersecond: user.lastName,
          email: user.email,
          Professor: user.isProfessor,
          Aluno: user.isAluno,
          Responsavel: user.isResponsavel,

        }));
        setData(mappedUsers);
      } catch (error) {
        console.error("Erro ao obter usuários:", error);
      }
    };

    fetchData();
  }, [userInfo]);

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUserById(userId);
      setData(prevData => prevData.filter(user => user.id !== userId));
    } catch (error) {
      console.error("Erro ao excluir usuário:", error);
    }
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Ação',
      width: 150,
      renderCell: (params) => (
        <>
          <Link to={`/user/${params.row.id}`}>
            <button className={styles.userListEdit}>Edit</button>
          </Link>
          <MdDeleteOutline
            className={styles.userListDelete}
            onClick={() => handleDeleteUser(params.row.id)}
          />
        </>
      ),
    },
    { field: 'Professor', headerName: 'Professor', width: 180 },
    { field: 'Responsavel', headerName: 'Responsavel', width: 180 },
    { field: 'Aluno', headerName: 'Aluno', width: 180 },
    { field: 'user', headerName: 'Nome', width: 200 },
    { field: 'usersecond', headerName: 'Sobrenome', width: 200 },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'Number', headerName: 'Número', width: 180 },
  ];

  const dashboardContent = (
    <div className={styles.all}>
      <div className={styles.home}>
        <div className={styles.returnPainelArrow}>
          <Link to='/'><MdKeyboardArrowLeft />Painel</Link>
        </div>
        <div className={styles.topAreaInfo}>
          <div className={styles.start}>
            <h1>Usuários da plataforma</h1>
            <p>Secretaria</p>
          </div>
          <div className={styles.end}>
            ...
          </div>
        </div>
        <div className={styles.userList}>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={8}
            disableSelectionOnClick
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );

  return (
    <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={dashboardContent} />
  );
};

export default UsuariosCoordenacao;
