import React, { useState, useEffect } from 'react';
import InterfaceStructure from '../../../components/InterfaceStructure';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Pagination } from '@mui/material';
import styles from './InformeComponents.module.css';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../components/tenantId';
import PopUpComponent from '../../popUp/PopUpComponent';
import MiniPopUpComponent from '../../popUp/MiniPopUpComponent';
import InformeComponentsCreate from './InformeComponentsCreate';
import InformeComponentsEdit from './InformeComponentsEdit';
import { Link } from 'react-router-dom';

const InformeComponents = ({ itemsPages }) => {
    const tenantId = useTenantId();
    const [informes, setInformes] = useState([]);
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedInformeId, setSelectedInformeId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [informeToDelete, setInformeToDelete] = useState(null); 
    const itemsPerPage = 10;

    useEffect(() => {
        const currentMonth = new Date().getMonth();
        setSelectedMonth(currentMonth);
    }, []);

    useEffect(() => {
        fetchInformes();
    }, [selectedMonth]);

    const fetchInformes = async () => {
        try {
            const response = await apiAxios.get(`/api/inf/${tenantId}`);
            if (response && response.data) {
                let filteredInformes = response.data;
                if (selectedMonth !== null) {
                    filteredInformes = response.data.filter(informe => {
                        const informeMonth = getMonthFromDateString(informe.dateFormatted);
                        return informeMonth === selectedMonth;
                    });
                }
                setInformes(filteredInformes);
            } else {
                setMessage('Erro ao buscar informes: Resposta inválida da API');
            }
        } catch (error) {
            setMessage(`Erro ao buscar informes: ${error.message}`);
        }
    };

    const getMonthFromDateString = (dateString) => {
        const dateParts = dateString.split('/');
        return parseInt(dateParts[1], 10) - 1;
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const requestDelete = (id) => {
        setInformeToDelete(id);
        setShowDeletePopup(true);
    };

    const confirmDelete = async () => {
        try {
            await apiAxios.delete(`/api/inf/${tenantId}/${informeToDelete}`);
            setMessage('Informe excluído com sucesso!');
            setInformes(informes.filter(informe => informe._id !== informeToDelete));
            setShowDeletePopup(false); // Fecha o popup após a exclusão
        } catch (error) {
            setMessage(`Erro ao excluir informe: ${error.message}`);
        }
    };

    const handleMonthChange = (month) => {
        setSelectedMonth(month);
        setPage(1);
    };

    const openCreatePopup = () => {
        setOpenCreateModal(true);
    };

    const closeCreatePopup = () => {
        setOpenCreateModal(false);
    };

    const openEditPopup = (informeId) => {
        setSelectedInformeId(informeId);
        setOpenEditModal(true);
    };

    const closeEditPopup = () => {
        setOpenEditModal(false);
        setSelectedInformeId(null);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const currentInformes = informes.slice(startIndex, startIndex + itemsPerPage);

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    };

    const months = Array.from({ length: 12 }, (v, k) => new Date(0, k).toLocaleString('default', { month: 'long' }));

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.home}>
                <div className={styles.topAreaInfo}>
                    <div className={styles.start}>
                        <h1>Informes</h1>
                        <p>Administrador</p>
                    </div>
                    <div className={styles.end}>
                        <Link onClick={openCreatePopup} className={styles.addButton}>
                            <IoIosAddCircleOutline /> Adicionar Informe
                        </Link>
                    </div>
                </div>
                <div className={styles.informesList}>
                    <div className={styles.monthsList}>
                        <div>
                            {months.map((month, index) => (
                                <button
                                    key={index}
                                    className={`${styles.monthButton} ${selectedMonth === index ? styles.selected : ''}`}
                                    onClick={() => handleMonthChange(index)}
                                >
                                    {month}
                                </button>
                            ))}
                        </div>
                    </div>
                    {currentInformes.map((informe) => (
                        <div key={informe._id} className={styles.informeItem}>
                            <div className={styles.topInformeItem}>
                                <button type="button" className={styles.updateButton} onClick={() => openEditPopup(informe._id)}>
                                    <MdEdit />
                                </button>
                                <button type="button" className={styles.removeButton} onClick={() => requestDelete(informe._id)}>
                                    <MdDeleteOutline />
                                </button>
                            </div>
                            <div className={styles.bottomInformeItem}>
                                <div className={styles.InfRight}>
                                    <img src={informe.imagem} alt='img' />
                                </div>
                                <div className={styles.InfLeft}>
                                    <div className={styles.topico}>{informe.topico}</div>
                                    <div className={styles.title}>{informe.titulo}</div>
                                    <div className={styles.desc}>{truncateText(informe.descricao, 50)}</div>
                                    <div className={styles.date}>{informe.dateFormatted}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.pagination}>
                    <Pagination
                        count={Math.ceil(informes.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        classes={{ ul: styles.pagination }}
                    />
                </div>
            </div>
            <PopUpComponent
                title="Adicionar Informe"
                isOpen={openCreateModal}
                onClose={closeCreatePopup}
            >
                <InformeComponentsCreate onClose={closeCreatePopup} />
            </PopUpComponent>

            {/* Popup para editar informe */}
            <PopUpComponent
                title="Editar Informe"
                isOpen={openEditModal}
                onClose={closeEditPopup}
            >
                {selectedInformeId && <InformeComponentsEdit informeId={selectedInformeId} onClose={closeEditPopup} />}
            </PopUpComponent>

            {/* Mini popup para confirmar exclusão */}
            {showDeletePopup && (
                <MiniPopUpComponent
                    title="Confirmar Exclusão"
                    message="Tem certeza de que deseja excluir este informe?"
                    onConfirm={confirmDelete}
                    onCancel={() => setShowDeletePopup(false)}
                />
            )}
        </div>
    );

    return (
        <InterfaceStructure itemsPages={itemsPages} dashboardContent={dashboardContent} />
    );
};

export default InformeComponents;
