// CalendarioViewComponent.js
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import apiAxios from '../../../../api/apiAxios';
import useTenantId from '../../../tenantId';
import styles from './CalendarioViewComponent.module.css';
import { IoClose } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { RiAddCircleLine } from "react-icons/ri";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import PopUpComponent from '../../../popUp/PopUpComponent';
import CalendarioEditComponent from '../CalendarioEditComponent';
import CalendarioCreateComponent from '../CalendarioCreateComponent';

const CalendarioViewComponent = ({ date, onClose, accessLevel }) => {
    const [calendario, setCalendario] = useState([]);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const tenantId = useTenantId();
    const contentRef = useRef(null);

    const fetchCalendario = async (selectedDate) => {
        try {
            const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
            const response = await apiAxios.get(`/api/calendar/by-date/${formattedDate}`, {
                params: { tenantId }
            });
            setCalendario(response.data);
        } catch (error) {
            console.error('Erro ao buscar calendário:', error);
        }
    };

    useEffect(() => {
        fetchCalendario(date);
    }, [date]);

    const handleClose = (e) => {
        if (!contentRef.current.contains(e.target)) {
            onClose();
        }
    };

    const handleDelete = async (eventId) => {
        try {
            await apiAxios.delete(`/api/calendar/${eventId}`);
            fetchCalendario(date);
            setDeleteSuccess(true);
            setTimeout(() => {
                setDeleteSuccess(false);
            }, 2000);
        } catch (error) {
            console.error('Erro ao deletar evento do calendário:', error);
        }
    };

    const handleEditClick = (event) => {
        setEventToEdit(event);
        setShowEditPopup(true);
    };

    const convertDescriptionToHTML = (descricao) => {
        if (!descricao) return '';
        try {
            const contentState = convertFromRaw(JSON.parse(descricao));
            return stateToHTML(contentState);
        } catch (error) {
            console.error('Erro ao converter descrição:', error);
            return 'Descrição inválida';
        }
    };

    return (
        <>
            <div className={styles.allScreen} onClick={handleClose}>
                <div ref={contentRef} className={`${styles.content} ${styles.slideIn}`}>
                    {calendario.map(event => (
                        <React.Fragment key={event._id}>
                            <div className={styles.closeButton}>
                                <div className={styles.title}>
                                    <div style={{ background: event.categoriaColor }}>.</div>
                                    <div className={styles.tit}>
                                        <h2>
                                            {event.Titulo}
                                            <h2 style={{ fontSize: "14px", color: "gray" }}>{event.date}</h2>
                                        </h2>
                                        <p>{event.categoriaName}</p>
                                    </div>
                                </div>
                                <button onClick={onClose}><IoClose /></button>
                            </div>
                            <div className={styles.conteudo}>
                                <div className={styles.desc}>
                                    <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHTML(event.descricao) }} />
                                </div>
                                {accessLevel === 'high' && (
                                  <div className={styles.actions}>
                                    <button onClick={() => setShowCreatePopup(true)} className={styles.create}><RiAddCircleLine /> Criar Novo</button>
                                    <button onClick={() => handleEditClick(event)} className={styles.edit}><BiEditAlt /> Editar</button>
                                    <button onClick={() => handleDelete(event._id)} className={styles.edit}><MdOutlineDeleteOutline /> Delete</button>
                                  </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {deleteSuccess && (
                <Snackbar
                    open={deleteSuccess}
                    autoHideDuration={3000}
                    onClose={() => setDeleteSuccess(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={() => setDeleteSuccess(false)}
                        severity="success"
                    >
                        Evento deletado com sucesso
                    </MuiAlert>
                </Snackbar>
            )}
            {showEditPopup && (
                <PopUpComponent title="Editar Evento" isOpen={showEditPopup} onClose={() => setShowEditPopup(false)}>
                    <CalendarioEditComponent event={eventToEdit} />
                </PopUpComponent>
            )}
            {showCreatePopup && (
                <PopUpComponent title="Criar Evento" isOpen={showCreatePopup} onClose={() => setShowCreatePopup(false)}>
                    <CalendarioCreateComponent />
                </PopUpComponent>
            )}
        </>
    );
};

export default CalendarioViewComponent;
